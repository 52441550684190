import React from "react"
import { Box, Container, Grid, GridItem, Text, Image, Center, Circle, Flex, Button, Heading } from "@chakra-ui/react"
import { PhoneIcon, CalendarIcon, EmailIcon, SettingsIcon } from "@chakra-ui/icons"
import { FaAngleRight } from 'react-icons/fa';
import { Link } from "react-router-dom"
import { motion } from "framer-motion"
import {
  ArrowForwardIcon
} from '@chakra-ui/icons';


const Info = () => {
  return(
    <Box bg="#F7FAFC" color="#000" w="100%" mb={10}>
      <Container maxW="container.xl" pt="60px">
        <Grid templateColumns='repeat(2, 1fr)' gap={2} d={{lg:"flex", md:"flex", sm: "column"}}>
          <GridItem w='100%' m={4}>
           
            <Text fontSize="4xl" as="b">Apa itu <span style={{color: '#48BB78'}}>Kampus Merdeka?</span> </Text> <br />
              <Text fontSize="2xl" mt="10px" as="b">Program persiapan karier yang komprehensif guna mempersiapkan generasi terbaik Indonesia</Text>
              <Text fontSize="md" mt="10px">
                Kampus Merdeka merupakan bagian dari kebijakan Merdeka Belajar oleh Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi Republik Indonesia yang memberikan kesempaatan bagi mahasiswa/i untuk mengasah kemampuan sesuai bakat dan minat dengan terjun langsung ke dunia kerja sebagai persiapan karier masa depan.
              </Text>
          </GridItem>
          <GridItem w='100%' m="4">
            <Image src="teams.svg"></Image>
          </GridItem>
        </Grid> 

        <br /><br /><br /><br />

        <Center m={8}>
          <Text mb={5} align="center" fontSize="2xl" as="b">Kenapa kamu harus mengikuti <br />  <span style={{color: '#48BB78'}}>program-program Kampus Merdeka?</span> </Text>
        </Center>

        <Box>
          <Grid templateColumns='repeat(4, 1fr)' gap={6} mb={12} d={{lg:"flex", md:"flex", sm: "column"}}>
      			<GridItem w='100%' p={3} mb={5} align="center">
              <Circle size='70px' bgGradient="linear(green.100 0%, green.200 25%, green.300 50%)" color='#fff'>
                <PhoneIcon w="30px" h="30px"/>
              </Circle>
              <Box m={4}>
                <Text fontSize="sm" as="b">Kegiatan praktik di lapangan akan dikonversi menjadi SKS</Text>
              </Box>
            </GridItem>

            <GridItem w='100%' p={3} mb={5} align="center">
              <Circle size='70px' bgGradient="linear(green.100 0%, green.200 25%, green.300 50%)" color='#fff'>
                <CalendarIcon w="30px" h="30px"/>
              </Circle>
              <Box m={4}>
                <Text fontSize="sm" as="b">Eksplorasi pengetahuan dan kemampuan di lapangan selama lebih dari satu semester</Text>
              </Box>
            </GridItem>

            <GridItem w='100%' p={3} mb={5} align="center">
              <Circle size='70px' bgGradient="linear(green.100 0%, green.200 25%, green.300 50%)" color='#fff'>
                <EmailIcon w="30px" h="30px"/>
              </Circle>
              <Box m={4}>
                <Text fontSize="sm" as="b">Belajar dan memperluas jaringan di luar program studi atau kampus asal</Text>
              </Box>
            </GridItem>

            <GridItem w='100%' p={3} mb={5} align="center">
              <Circle size='70px' bgGradient="linear(green.100 0%, green.200 25%, green.300 50%)" color='#fff'>
                <SettingsIcon w="30px" h="30px"/>
              </Circle>
              <Box m={4}>
                <Text fontSize="sm" as="b">Menimba ilmu secara langsung dari mitra berkualitas dan terkemuka</Text>
              </Box>
            </GridItem>

      			</Grid>
          
        </Box>

      </Container>
    </Box>
  )
}

export default Info;
