
import { useState, useRef, useEffect } from "react";
import Axios from "services/AxiosConfig";
import { useParams } from "react-router-dom";
import { Spinner, useToast, Text, Center, HStack, Flex, Checkbox, Link,  Container, Box, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableContainer, Button, Badge, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,  Image} from "@chakra-ui/react";
import baseURL from "../../services/BaseUrl.js";


export default function LaporanMhs() {

	const {id} = useParams();
	const toast = useToast();

	const[modalDetail, setModalDetail] 			= useState(false);
	const[logbookMhs, setLogbookMhs]	 			= useState([]);
	const[logbookDetail, setLogbookDetail]	= useState("");
	const[lampiran, setLampiran]	 					= useState([]);
	const[loadingDetail, setLoadingDetail]	= useState(false);

	useEffect(() => {
		getLogbookMhs();
	},[]);

	const getLogbookMhs = async () => {
		await Axios.get("dpl/getLogbookMhs/"+id)
					.then(({data}) => {
						setLogbookMhs(data.logbook)
					}).catch(err => {
	            const {status, data: {message}} = err.response;
	              toast({
		             title: `Gagal (${status})!`,
		             description: `${message}`,
		             status: 'error',
		             duration: 5000,
		             isClosable: true,
		           })
	          })
	}


	const getDetailLogbook = async (id) => {
		setModalDetail(true);
		setLoadingDetail(true)
		await Axios.get("dpl/getLogbookMhsDetail/"+id)
					.then(({data}) => {
						setLogbookDetail(data.logbookDetail);
						setLampiran(data.lampiran);
						setLoadingDetail(false)
					})
					.catch(err => {
	            const {status, data: {message}} = err.response;
	            setLoadingDetail(false)
	              toast({
		             title: `Gagal (${status})!`,
		             description: `${message}`,
		             status: 'error',
		             duration: 5000,
		             isClosable: true,
		           })
	          })
	}

	return(
		<>
			<Box bg="#F5DF99" p={3} justifyContent="space-between" as={Flex} alignItems="center">
				<Text fontWeight="bold">Aktivitas : Logbook </Text>
				<Link to="#" color="teal" mr="10px">Setujui semua</Link>
			</Box>
			<Box w="full" p={3} bg="#fff" >
				<TableContainer mt={5}>
				  <Table variant='simple' size="sm">
				    <Thead>
				      <Tr>
				        <Th>No</Th>
				        <Th>Minggu Ke-</Th>
				        <Th>Judul Kegiatan</Th>
				        <Th>Status</Th>
				        <Th>AKSI</Th>
				      </Tr>
				    </Thead>
				    <Tbody>
				      
				      {
				      	logbookMhs.length > 0 ? (
				      		<>
				      			{
				      				logbookMhs.map((item, index) => (
				      					<Tr key={index}>
									        <Td>{index + 1}</Td>
									        <Td>{item?.tgl_kegiatan}</Td>
									        <Td>
									        	<div dangerouslySetInnerHTML={{ __html: item?.nama_kegiatan }} />
									        </Td>
									        <Td>
									        	{
									        		item?.status === 'verified' ? <Badge colorScheme='green' variant="solid">Disetujui</Badge> : <Badge colorScheme='red' variant="solid">Belum disetujui</Badge>
									        	}
									        </Td>
									        <Td>
									        		<Button onClick={() => getDetailLogbook(id)} size="xs" borderRadius="sm" colorScheme='teal' variant='solid'>
												    Detail
												  </Button>
									        </Td>
									      </Tr>
				      				))
				      			}
				      		</>
				      	) : (
				      		<Tr colspan="5">
				      			<Center>Tidak ada logbook</Center>
				      		</Tr>
				      	)
				      }
				      
				    </Tbody>
				  </Table>
				</TableContainer>
			</Box>

			<ModalDetail getLogbookMhs={getLogbookMhs} loading={loadingDetail} modalDetail={modalDetail} setModalDetail={setModalDetail} logbook={logbookDetail} lampiran={lampiran} />

		</>
	)
}


const ModalDetail = ({modalDetail, setModalDetail, logbook, lampiran, loading, getLogbookMhs}) => {

	const toast = useToast();
	const [scrollBehavior, setScrollBehavior] = useState('inside');
	const btnRef = useRef(null)
	const[checked, setChecked] = useState(false)
	const[loadingRead, setLoadingRead] = useState(false);


	const handleClick = async () => {
		setLoadingRead(true)
		await Axios.post("dpl/readLogbook/"+logbook.id)
					.then(({data}) => {
						setLoadingRead(false);
						setModalDetail(false);
						setChecked(false);
						toast({
                title: `Berhasil!`,
                description: `${data.message}`,
                status: 'success',
                duration: 5000,
                isClosable: true,
              });
						getLogbookMhs();
					})
					.catch(err => {
	            const {status, data: {message}} = err.response;
	              toast({
		             title: `Gagal (${status})!`,
		             description: `${message}`,
		             status: 'error',
		             duration: 5000,
		             isClosable: true,
		           });
							setLoadingRead(false)
	          })
	}

	return(
		<Modal isOpen={modalDetail} onClose={modalDetail} size="full" finalFocusRef={btnRef} scrollBehavior={scrollBehavior}>
        <ModalOverlay />
        <ModalContent bg="#F6F8FA">
          <ModalHeader>
          	<Center>
          		DETAIL LOGBOOK
          	</Center>
          </ModalHeader>
          <ModalCloseButton onClick={() => setModalDetail(false)} />
          <ModalBody bg="#F6F8FA">
            	<Container maxW="800">
		          	{
		          		loading ? (
		          			<Box minH="70vh" as={Flex} justifyContent="center" alignItems="center">
		          				<Spinner color='#FAC213' />
		          			</Box>

		          		) : (

		          			<>
		          				<Box>
					          		<Text fontWeight="bold" mb={2}>
					          			Nama kegiatan / sub kegitan
					          		</Text>
					          		<div dangerouslySetInnerHTML={{ __html: logbook.nama_kegiatan }} />
					          	</Box>

					          	<Box mt={5}>
					          		<Text fontWeight="bold" mb={2}>
					          			Tujuan kegiatan
					          		</Text>
					          		<div dangerouslySetInnerHTML={{ __html: logbook.tujuan_kegiatan }} />
					          	</Box>

					          	<Box mt={5}>
					          		<Text fontWeight="bold" mb={2}>
					          			Catatan
					          		</Text>
					          		<div dangerouslySetInnerHTML={{ __html: logbook.catatan }} />
					          	</Box>

					          	<Box mt={5}>
					          		<Text fontWeight="bold" mb={2}>
					          			Pemecahan masalah
					          		</Text>
					          		<div dangerouslySetInnerHTML={{ __html: logbook.pemecahan_masalah }} />
					          	</Box>

					          	<Box mt={5}>
					          		<Text fontWeight="bold" mb={2}>
					          			Kesimpulan
					          		</Text>
					          		<div dangerouslySetInnerHTML={{ __html: logbook.pemecahan_masalah }} />
					          	</Box>

					          	<Box mt={5}>
					          		<Text fontWeight="bold" mb={2}>
					          			Rencana kegiatan selanjutnya
					          		</Text>
					          		<div dangerouslySetInnerHTML={{ __html: logbook.rencana }} />
					          	</Box>

					          	<Box mt={5}>
						          		<Text fontWeight="bold" mb={2}>
						          			Lampiran
						          		</Text>

						          		{
						          			lampiran.length > 0 ? (
						          				<Box my={1}>
						          					{
						          						lampiran.map((item, index) => (
									          				<Box key={index} mb={3}>
									          					<Image src={baseURL+'assets/upload/gambar/logbook_kegiatan/'+item.gambar} alt="lampiran" />
									          				</Box>
									          			))
						          					}
						          				</Box>
						          				) : ""
						          		}

						          	</Box>

					          	</>

					         )
		          	}
					   </Container>
          </ModalBody>

          <ModalFooter bg="#F6F8FA">
          		
            	{
            		logbook.status === 'not_verified' ? (
            				<>
            					<Checkbox mr="10px" colorScheme='green' isChecked={checked} onChange={() => setChecked(!checked)}>
			          			<Text fontSize="14px">
			          					Saya Tri Indah Rusli, S.Pd., M.Pd, selaku DPL akan mengkonfirmasi Logbook ini.</Text>
			          			</Checkbox>
            					<Button variant='solid' isLoading={loadingRead}  onClick={() => handleClick()} colorScheme="whatsapp" borderRadius="sm" disabled={!checked || loadingRead}>Konfirmasi</Button>
            				</>
            			)
            		: <Button variant='solid'  colorScheme="red" borderRadius="sm" onClick={() => setModalDetail(false)}>Close</Button>

            	}
          </ModalFooter>
        </ModalContent>
      </Modal>
	)
}