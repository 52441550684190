

import { useState } from "react";
import { Box, Text, Icon, Link as LinkChakra, HStack, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { MdCheckCircle, MdKeyboardArrowRight, MdOutlineVideoSettings } from "react-icons/md";

export default function FAQ() {

	return(
		<>
			<Box p={4} bg="#F5DF99">
				<Text fontWeight="bold">FAQ (Frequently Asked Questions)</Text>
			</Box>
			<Box bg="#fff" p={4}>
				<Accordion allowToggle>
				  <AccordionItem>
				    <h2>
				      <AccordionButton>
				        <Box flex='1' textAlign='left'>
				          <Text color="teal">Bagaimana cara melihat dan menyetujui logbook mahasiswa ?</Text>
				        </Box>
				        <AccordionIcon />
				      </AccordionButton>
				    </h2>
				    <AccordionPanel pb={4}>
				      <Box my={2} p={3}>

				      <HStack spacing={2}>
				      	<Icon as={MdCheckCircle} />
			      	  	<Text>
			      	  		Silahkan klik menu <Link style={{color: '#D657A5'}}  to="/dpl/bimbingan">Bimbingan, </Link> maka akan ditampilan list bimbingan anda untuk saat ini.
			      	  	</Text>
				      </HStack>
				      <HStack mt={3}>
				      	<Icon as={MdCheckCircle} />
			      	  	<Text>
			      	  		Pilih salah satu mahasiswa dengan mengklik tombol <span style={{color: '#D657A5'}}>aktivitas</span>, Maka anda akan diarahkan kehalaman aktivitas mahasiswa, dihalaman tersebut anda bisa melihat logbook dan laporan mahasiswa.
			      	  	</Text>
				      </HStack>
				      <HStack mt={3}>
				      	<Icon as={MdCheckCircle} />
			      	  	<Text>
			      	  		Untuk menyetujui logbook mahasiswa silakan ke menu  <span style={{color: '#D657A5'}}>Logbook</span> > klik menu  <span style={{color: '#D657A5'}}>Detail</span>, maka akan muncul popup.
			      	  	</Text>
				      </HStack>
				      <HStack mt={3}>
				      	<Icon as={MdCheckCircle} />
			      	  	<Text>
			      	  		Silahkan anda baca, kemudian jika setuju silahkan anda centang <span style={{color: '#D657A5'}}>Checbox</span> yang berada dibawah layar anda > klik menu  <span style={{color: '#D657A5'}}>Menyetujui</span>, maka status logbook mahasiswa akan berubah menjadi <span style={{color: '#D657A5'}}>Disetujui</span>.
			      	  	</Text>
				      </HStack>

				      <HStack mt={"20px"}>
				      	<Icon as={MdOutlineVideoSettings} color="#D657A5" fontSize="xl" /> 
				      	<Text as={LinkChakra} href='https://chakra-ui.com' isExternal color="#D657A5" _hover={{cursor: "pointer"}}>video</Text>
				      </HStack>

				      </Box>
				    </AccordionPanel>
				  </AccordionItem>

				  <AccordionItem>
				    <h2>
				      <AccordionButton>
				        <Box flex='1' textAlign='left'>
				          <Text color="teal">Bagaimana cara mengisi nilai ?</Text>
				        </Box>
				        <AccordionIcon />
				      </AccordionButton>
				    </h2>
				    <AccordionPanel pb={4}>
				      <Box py={4}>

				      	<HStack spacing={4}>
					      	<Icon as={MdCheckCircle} />
				      	  	<Text>
				      	  		Silahkan klik menu <Link style={{color: '#D657A5'}}  to="/dpl/bimbingan">Bimbingan, </Link> maka akan ditampilan list bimbingan anda untuk saat ini.
				      	  	</Text>
					      </HStack>
					      <HStack mt={2} spacing={4}>
					      	<Icon as={MdCheckCircle} />
				      	  	<Text>
				      	  		Klik tombol <span style={{color: '#D657A5'}}>Input Nilai</span>, maka anda akan diarahkan ke halaman pengisian nilai.
				      	  	</Text>
					      </HStack>
					      <HStack mt={2} spacing={4}>
					      	<Icon as={MdCheckCircle} />
				      	  	<Box>
				      	  		<Text>
				      	  		Input nilai dengan ketentuan :
					      	  	</Text>
					      	  	<ul style={{marginLeft: "20px"}}>
					      	  		<li>Angka yang anda masukkan hanya boleh 2 angka <span style={{color: '#D657A5'}}>(1-100)</span> , ex. <span style={{color: '#D657A5'}}>(60, 70, dst..)</span>.</li>

					      	  		<li><span style={{color: '#D657A5'}}>Grade</span> akan secara <span style={{ color: "#D657A5"}}>realtime</span> berubah.</li>
					      	  	</ul>
				      	  	</Box>
					      </HStack>
					      <HStack mt={2} spacing={4}>
					      	<Icon as={MdCheckCircle} />
				      	  	<Text>
				      	  		Klik tombol <span style={{color: '#D657A5'}}>Simpan</span>, makan nilai akan tersimpan.
				      	  	</Text>
					      </HStack>

					       <HStack mt={"20px"}>
					      	<Icon as={MdOutlineVideoSettings} color="#D657A5" fontSize="xl" /> 
					      	<Text as={LinkChakra} href='https://chakra-ui.com' isExternal color="#D657A5" _hover={{cursor: "pointer"}}>video</Text>
					      </HStack>


				      </Box>
				    </AccordionPanel>
				  </AccordionItem>
				</Accordion>
			</Box>
		</>
	)
}