
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "services/AxiosConfig"

import { Text, Box, Flex, HStack, Table,Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Tr,
  Th,
  Td,
  Tbody,
  TableCaption, } from "@chakra-ui/react";

import {  Button, Input, Empty } from '@arco-design/web-react';
import { IconSearch } from '@arco-design/web-react/icon';

const Pendaftaran = () => {


  const [nim, setNim]             = useState("");
  const [loading, setLoading]     = useState(false)
  const [mahasiswa, setMahasiswa] = useState(null)

  const handleClick = async () => {
    setLoading(true)
    await Axios.post("admin/getMahsiswaByNim", {nim:nim})
          .then(({data}) => {
            setMahasiswa(data);
            setLoading(false);
          })
          .catch( err => {
            setLoading(false);
              console.log(err)
          });

  }

  return(
    <>
    <Text fontSize="xl" as="b">Pendaftaran peserta tahun akademik 2021/Ganjil</Text>
      <Box as={Flex} alignItems="center" bg="white" w="100%" mt={2} h="70px" p={4}>
        <HStack spacing="15px">
          <Box w="400px">
            <Input
              onChange={e => setNim(e)}
              allowClear
              value={nim}
              maxLength={8}
              showWordLimit
              disabled={loading}
              placeholder='Masukkan NIM Mahasiswa...'
            />
          </Box>
          <Box w="200px">
            <Button onClick={handleClick} loading={loading} icon={<IconSearch />} type='outline'>
              Cari mahasiswa
            </Button>
          </Box>
        </HStack>
      </Box>
      <Box>
      </Box>

      <Box mt="20px">
        {
          mahasiswa === null ? <Empty /> : <ResultMahasiswa data={mahasiswa} />
        }
      </Box>
    </>
  )
}

export default Pendaftaran;

const ResultMahasiswa = ({data}) => {

  let navigate = useNavigate();
  
  return(
    <>
    <Table>
      <TableCaption>
        {
          data?.status_daftar === null && (
            <Button type='primary' onClick={() => navigate(`${data.nim}`)}>Daftarkan</Button>
          )
        }
      </TableCaption>
        <Tbody >
          <Tr>
            <Th>Nama</Th>
            <Td>: {data?.nama_lengkap}</Td>
          </Tr>
          <Tr>
            <Th>NIM</Th>
            <Td>: {data?.nim}</Td>
          </Tr>
          <Tr>
            <Th>Status Daftar</Th>
            <Td>: {data?.status_daftar !== null ? "Sudah melakukan pendftaran" : "Belum melakukan pendaftaran" }</Td>
          </Tr>
        </Tbody>
      </Table>

    </>
  )
}
