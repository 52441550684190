
import { useState, useEffect } from 'react';
import Axios from "services/AxiosConfig";
import { Text, Box, Table, Thead, Tbody, Tfoot, Tr, Th, Td, Button, Image , useToast} from "@chakra-ui/react";
import { BiPlus } from "react-icons/bi";
import { Link } from "react-router-dom";
import baseURL from 'services/BaseUrl'


const Program = () => {

	let toast = useToast();

	const[programs, setPrograms] = useState([]);
	const[loading, setLoading] = useState(false);

	useEffect(() => {
		getDataPrograms();
	}, []);

	const getDataPrograms = async () => {
		await Axios.get('admin/getDataPrograms')
			  .then(({data}) => {
			  	setPrograms(data.programs)
			  	setLoading(false)
			  })
			  .catch(err => {
			  	console.log(err)
			  	setLoading(false)
			  })
	}

	const handleDelete = async (id) => {
		setLoading(true)
		await Axios.delete('admin/deleteProgram/'+id)
				.then(res => {

					const newPrograms = programs.filter(t => t.id !== id);

					setPrograms(newPrograms);

					const {status, data: {message}} = res;
					toast({
	                title: 'Sukses!',
	                description: message,
	                status: 'success',
	                duration: 5000,
	                isClosable: true,
	                position: "bottom"
	              });
					setLoading(false)
				})
				.catch(err => {
					const {status, data: {message}} = err.response;
	            setLoading(false)
	              toast({
		             title: `Gagal (${status})!`,
		             description: `${message}`,
		             status: 'error',
		             duration: 5000,
		             isClosable: true,
		           })
				})
	}

	return(
		<>
			<Text fontSize="xl" as="b">Program MBKM FKIP</Text>
			 <br />
			 <Button as={Link} to="tambah" mt={4} colorScheme='teal' variant='solid' size="sm" borderRadius="sm" leftIcon={<BiPlus />}>Tambah</Button>
			<Box w="100%" bg="white" mt={2} p={4}>
				  <Table variant='simple'>
				    <Thead>
				      <Tr>
				        <Th>No</Th>
				        <Th w="100">Gambar</Th>
				        <Th>Judul</Th>
				        <Th>Status</Th>
				        <Th>Aksi</Th>
				      </Tr>
				    </Thead>
				    <Tbody>
				      	{
				      		programs.map((program, i) => (
					      		<Tr>
							        <Td>{i+1}</Td>
							        <Td>
							        		<Image w="100" src={baseURL+'assets/upload/gambar/'+program?.background} />
							        </Td>
							        <Td>{program?.nama_kegiatan}</Td>
							        <Td>
							        	{program?.status === 'Y' ? 'Aktif' : 'Tidak aktif'}
							        </Td>
							        <Td>
							        		 <Button colorScheme='red' isLoading={loading} disabled={loading} size='xs' borderRadius="xs" onClick={() => handleDelete(program.id)}>Hapus</Button>
							        </Td>
							      </Tr>
				      		))
				      	}
				    </Tbody>
				  </Table>
			</Box>

		</>
	);
}

export default Program;