
import { useState } from "react";
import Axios from "services/AxiosConfig";
import { useToast, Text, Box, Flex, Icon,  FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Textarea, Button, Center} from "@chakra-ui/react";
import { BiCloudUpload, BiPaperPlane } from "react-icons/bi";

const TambahProgram = () => {
	let toast = useToast();

	const[loading, setLoading]			= useState(false);
	const[nama, setNama] 				= useState("");
	const[deskripsi, setDeskripsi]	= useState("");
	const[file, setFile]					= useState("");

	const handleChangeFile = (e) => {
		const file = e.target.files[0];
		setFile(file)
	}

	const handleSubmit = async () => {

		setLoading(true)

		let formData = new FormData();

		formData.append('file', file);
		formData.append('nama', nama);
		formData.append('deskripsi', deskripsi);

		await Axios.post('admin/addProgram', formData)
				.then(res => {
					const {status, data: {message}} = res;
					toast({
	                title: 'Sukses!',
	                description: message,
	                status: 'success',
	                duration: 5000,
	                isClosable: true,
	                position: "bottom"
	              });
					setNama("")
					setDeskripsi("")
					setFile("")
					setLoading(false)
				})
				.catch(err => {
					const {status, data: {message}} = err.response;
	            setLoading(false)
	              toast({
		             title: `Gagal (${status})!`,
		             description: `${message}`,
		             status: 'error',
		             duration: 5000,
		             isClosable: true,
		           })
				})


	}

	return (
		<>
			<Text fontSize="xl" as="b">Tambah Program MBKM FKIP</Text>

			<Box w="100%" bg="#fff" mt={4} p={9}>
				
				<Box w="100%">
					<FormControl>
					  <FormLabel>Nama kegiatan</FormLabel>
					  <Input type='text' value={nama} placeholder="Masukkan judul kegiatan..." borderRadius="sm" onChange={e => setNama(e.target.value)} />
					  {/*<FormHelperText>We'll never share your email.</FormHelperText>*/}
					
					</FormControl>
					<FormControl mt={4}>
					  <FormLabel>Gambar</FormLabel>
					  <Input type='file'  borderRadius="sm" onChange={e => handleChangeFile(e)} />
					  {/*<FormHelperText>We'll never share your email.</FormHelperText>*/}
					
					</FormControl>
					<FormControl mt={5}>
						<FormLabel>Deskripsi kegiatan</FormLabel>
					  <Textarea value={deskripsi} onChange={e => setDeskripsi(e.target.value)} placeholder="Masukkan deskripsi kegiaran..." borderRadius="sm" />
					  {/*<FormHelperText>We'll never share your email.</FormHelperText>*/}
					 </FormControl>
					 <br />
					 <Center>
					 	<Button disabled={loading} isLoading={loading} colorScheme='teal' size='md' onClick={() => handleSubmit()} borderRadius="sm" leftIcon={<BiPaperPlane/>}>Simpan</Button>
					 </Center>
				</Box>

			</Box>
		</>
	)
}

export default TambahProgram;