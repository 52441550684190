


import { useNavigate } from "react-router-dom"

import "@arco-design/web-react/dist/css/arco.css";

import { Menu } from '@arco-design/web-react';
import { IconApps, IconHome, IconUserGroup, IconArchive, IconSettings, IconQuestionCircle, IconExport } from '@arco-design/web-react/icon';

const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const NavItem = () => {

  let navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login')
  }

  return(
    <div
      className='menu-demo'
      style={{
        width: "100%",
        marginTop:"10px",
        height:"100%"
      }}
    >

      <Menu
        style={{ width: 200, height: '100%' }}
      >
      <MenuItem key='45' onClick={() => navigate('/admin')}>
        <IconApps />
        Dashboard
      </MenuItem>
        <SubMenu
          key='0'
          title={
            <>
              <IconUserGroup /> Aktivitas
            </>
          }
        >
          <MenuItem key='0_0' onClick={() => navigate('/admin/peserta')}>
            Peserta
          </MenuItem>
          <MenuItem key='0_1' onClick={() => navigate("/admin/bimbingan")}>
            Bimbingan
          </MenuItem>
          <MenuItem key='40' onClick={() => navigate("/admin/pendaftaran")}>
            Pendaftaran
          </MenuItem>
        </SubMenu>
        <SubMenu
          key='1'
          title={
            <>
            <IconArchive />Data Center
            </>
          }
        >
          <MenuItem key='1_0' onClick={() => navigate("/admin/sekolah")}>Data sekolah</MenuItem>
          <MenuItem key='1_1' onClick={() => navigate("/admin/program")}>Program</MenuItem>
        </SubMenu>
        <MenuItem key='46' onClick={() => navigate("/admin/pengaturan")}>
          <IconSettings />
          Pengaturan
        </MenuItem>
        <MenuItem key='23' onClick={() => navigate("/admin/bantuan")}>
          <IconQuestionCircle />
            Bantuan
        </MenuItem>
        <MenuItem key='345' onClick={() => handleLogout()}>
          <IconExport />
            Keluar
        </MenuItem>
      </Menu>
    </div>
  )
}

export default NavItem
