
import {useParams} from "react-router-dom";
import {useState} from "react"
import Axios from "services/AxiosConfig"

import {Text, Box, Flex} from "@chakra-ui/react";
import { Form, Input, Button, InputNumber, Message, Radio, DatePicker, Select, Upload } from '@arco-design/web-react';
const Option = Select.Option;
const cities = ['Beijing', 'Shanghai', 'Guangzhou', 'Shenzhen', 'Chengdu', 'Wuhan'];

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

const PendaftaranResult = () => {

  let {nim} = useParams();
  const [layout, setLayout] = useState('horizontal');
  const [suratIzin, setSuratIzin] = useState([]);
  const [buktiBayar, setBuktiBayar] = useState();
  const [tglBayar, setTglBayar] = useState("");
  const [lokasi, setLokasi] = useState("");
  const [kepesertaan, setKepesertaan] = useState("");
  const [noWa, setNoWA] = useState("");
  const [ukuranBaju, setUkuranBaju] = useState("");
  const [kegiatan, setKegiatan] = useState("")

  const handleSubmit = () => {
      const formData = new FormData();

      formData.append("nim", nim);
      formData.append("suratIzin", suratIzin);
      formData.append("buktiBayar", buktiBayar);
      formData.append("tglBayar", tglBayar);
      formData.append("lokasi", lokasi);
      formData.append("kepesertaan", kepesertaan);
      formData.append("noWA", noWa);
      formData.append("ukuranBaju", ukuranBaju);
      formData.append("kegiatan", kegiatan);

      Axios.post("mahasiswa/daftar", formData, {headers: {
        "Content-Type" : "multipart/form-data"
      }})
      .then(res => console.log(res))

  }


  return(
    <Box w="100%" bg="#fff" p="20px">
      <Form onSubmit={handleSubmit}>
        <FormItem label='Surat Izin Rekomendasi PA'>
          <input type="file" onChange={e => setSuratIzin(e.target.files[0])} />
        </FormItem>
        <FormItem label='Bukti Bayar'>
          <input type="file" onChange={e => setBuktiBayar(e.target.files[0])} />
        </FormItem>
        <FormItem label='Tangga Bayar'>
          <DatePicker onChange={e => setTglBayar(e)} />
        </FormItem>
        <FormItem label='Jenis kegiatan'>
          <Select onChange={e => setKegiatan(e)} placeholder='Pilih kegiatan' style={{ width: 154 }} allowClear showSearch  style={{ width: 600 }}>
              {cities.map((option, index) => (
                <Option key={option} disabled={index === 3} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
        </FormItem>
        <FormItem label='Lokasi'>
          <Select onChange={e => setLokasi(e)} placeholder='Pilih lokasi anda' style={{ width: 154 }} allowClear showSearch  style={{ width: 600 }}>
              {cities.map((option, index) => (
                <Option key={option} disabled={index === 3} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
        </FormItem>
        <FormItem label='Jenis Kepesertaan'>
          <Select onChange={e => setKepesertaan(e)} placeholder='Pilih'allowClear showSearch  style={{ width: 600 }}>
                <Option key="1" value="perorangan">Perorangan</Option>
                <Option key="2" value="kelompok">Kelompok</Option>
            </Select>
        </FormItem>
        <FormItem label='Nomor WA'>
          <InputNumber
            placeholder='Masukkan nomor WA'
            min={0}
            max={12}
            onChange={e => setNoWA(e)}
            style={{ width: 200}}
          />
        </FormItem>
        <FormItem label='Ukuran Baju'>
          <RadioGroup defaultValue='m' style={{ marginBottom: 20 }} onChange={e => setUkuranBaju(e)}>
            <Radio value='sm'>SM</Radio>
            <Radio value='m'>M</Radio>
            <Radio value='lg'>LG</Radio>
            <Radio value='xl'>XL</Radio>
            <Radio value='xxl'>XXL</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem
          wrapperCol={{
            offset: 5,
          }}
        >
          <Button type='primary' onClick={handleSubmit  }>Daftar sekarang</Button>
        </FormItem>
      </Form>
    </Box>
  )
}

export default PendaftaranResult;
