
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Axios from "services/AxiosConfig";
import { Text, Button, Box, useToast, Link, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, 
  ModalCloseButton, } from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import baseURL from "services/BaseUrl"
import YouTube from 'react-youtube';

const opts = {
      height: '300',
      width: '100%',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
      },
    };

export default function DPLBimbinganLaporan() {

	const {id} = useParams();
	let toast = useToast();

	const[laporan, setLaporan] = useState();
	const[loading, setLoading] = useState(false);
	const[modalVideo, setModalVideo] = useState(false);
	const[idYoutube, setIdYoutube] = useState(null);

	useEffect( async () => {
		setLoading(true)
		await Axios.get("dpl/getLaporanMhs/"+id)
				.then(({data}) => {
					setLaporan(data.laporan)
					setLoading(false)
				})
				.catch(err => {
	            const {status, data: {message}} = err.response;
	              toast({
		             title: `Gagal (${status})!`,
		             description: `${message}`,
		             status: 'error',
		             duration: 5000,
		             isClosable: true,
		           });
					setLoading(false)
	          })

	},[]);

	const handleClick = (idYoutube) => {
		setModalVideo(true);
		setIdYoutube(idYoutube)
	}

	return(
		<> 
			{
				laporan?.laporan !== null || laporan?.laporan !== "" ? (
					<>
						<Box bg="#F5DF99" p={3}>
							<Text fontWeight="bold">Aktivitas : Laporan Akhir </Text>
						</Box>
						<Box bg="#fff" p={4}>
							<Text fontWeight="bold">Laporan </Text>
							<Box mt={2} mb={3} p={3} border="1px dashed">
								<Link color='teal.500' href={baseURL+'assets/upload/laporan/'+laporan?.laporan} isExternal>{laporan?.laporan} <ExternalLinkIcon mx='2px' /></Link>
							</Box>

							<Text fontWeight="bold">Video kegiatan </Text>
							<Box my={2} p={3} border="1px dashed">
								<Link color='teal.500' href="#" onClick={() => handleClick(laporan?.link_kegiatan_magang)}>{laporan?.link_kegiatan_magang}</Link>
							</Box>

						</Box>
						<Modal isOpen={modalVideo} onClose={modalVideo} size="xl">
				        <ModalOverlay />
				        <ModalContent>
				          <ModalHeader>Modal Title</ModalHeader>
				          <ModalCloseButton onClick={() => setModalVideo(false)} />
				          <ModalBody>
									<YouTube videoId={idYoutube} opts={opts}  />
				          </ModalBody>

				          <ModalFooter>
				            <Button colorScheme='blue' mr={3} onClick={() => setModalVideo(false)}>
				              Close
				            </Button>
				            <Button variant='ghost'>Secondary Action</Button>
				          </ModalFooter>
				        </ModalContent>
				      </Modal>
					</>
				) : (
					<Text>Belum upload laporan</Text>
				)
			}
		</>
	)
}