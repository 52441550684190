import { Outlet, Navigate } from "react-router-dom";

const useAuth = () => {
	const userToken = localStorage.getItem('token');

	const user = { loggedIn: userToken ? true : false }

	return user.loggedIn;
}

const ProtectedRoutes = () => {

	const isAuth = useAuth();
	return isAuth ? <Outlet /> : <Navigate to="/login" replace />;
}

export default ProtectedRoutes;