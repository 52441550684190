import { Link, Outlet } from "react-router-dom"

import { Box, Container, Flex, Text, HStack, Stack, Image, Button } from "@chakra-ui/react";

const activeStyle = {
  fontWeight: "bold",
  borderBottom: "1px",
  borderColor: "blue.700",
  cursor: "pointer"
}

const Kegiatan = () => {
  return(

    <Container maxW="1118px"  py={3}>
      <Box bg="white" borderRadius="md" boxShadow="sm">
        <Box py={3} px={5}>
          <Text fontSize="2xl" fontWeight="bold" mb={4}>Kegiatanku</Text>
        </Box>

        <Stack direction={['column', 'row']} spacing='20px' mx={5} pb={2}>
          <Box style={activeStyle}>
            <Text fontSize="15px" as={Link} to="/mhs/kegiatan/status">Status Pendaftaran</Text>
          </Box>
          <Box style={{ cursor: "pointer" }} color="gray.400">
            <Text fontSize="15px" as={Link} to="/mhs/kegiatan/kegiatanKu">Kegiatan Aktif</Text>
          </Box>
        </Stack>

      </Box>

    <Box>
      <Outlet />
    </Box>

    </Container>

  )
}

export default Kegiatan;
