
import { useParams, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react";
import Axios from "services/AxiosConfig"

import { Text, Box, HStack, Flex, Center, useToast} from "@chakra-ui/react"

import { Button, Dropdown, Menu, Modal, Select, Aler, Popconfirm } from '@arco-design/web-react';
import { IconArrowLeft, IconDown } from '@arco-design/web-react/icon';


const Option = Select.Option;

export default function BimbinganEdit() {

  let {semester, tahun_akademik, id_dosen} = useParams();
  let navigate = useNavigate();
  let toast = useToast();

  const [bimbingan, setBimbingan] = useState([]);
  const [modal, setModal] = useState(false);
  const [errorsUpdate, setErrosUpdate] = useState([]);

  useEffect( () => {
    getDataBimbingan();
  }, []);

  const getDataBimbingan = async () => {
    await  Axios.post("admin/getDataBimbinganDetail", {semester, tahun_akademik, id_dosen})
          .then(({data}) => {
            setBimbingan(data);
          });
  }


  return(
    <>
    <Button type='primary' status='danger' icon={<IconArrowLeft />} onClick={() => navigate(`/admin/bimbingan/${tahun_akademik}/${semester}`)}>
      Kembali
    </Button>

    <Box w="100%" bg="white" mt={5} p={4}>
      <Text fontWeight="bold" fontSize="xl">{bimbingan[0]?.nama_dosen}</Text>
    </Box>
    <br />

    <Button type='primary' onClick={() => setModal(true)}>Tambah Bimbingan</Button>

    {
      errorsUpdate.length > 0 && (
        <Box mt="10p" py="10px">
              <li>{errorsUpdate}</li>
        </Box>
        )
    }

    <HStack w="full" p={2} mt="10px" spacing="10px" fontSize="12px">
      <Box as={Flex} w="250px">
        <Box>
          <Text fontWeight="bold">NIM</Text>
        </Box>
      </Box>
      <Box w="200px">
        <Text fontWeight="bold">Nama Mahasiswa</Text>
      </Box>
      <Box w="150px">
        <Text fontWeight="bold">Lokasi</Text>
      </Box>
      <Box w="150px">
        <Text fontWeight="bold">Jenis Kegiatan</Text>
      </Box>
      <Box>
        <Text fontWeight="bold" ml="15px" w="200px">Pengaturan</Text>
      </Box>
    </HStack>

    {
      bimbingan.map((item, index) => (
        <HStack  bg="white" w="full" p={2} mt={2} spacing="10px" fontSize="12px" key={index}>
          <Box as={Flex} w="250px">
            <Box>
              <Text fontWeight="medium">{item?.nim}</Text>
            </Box>
          </Box>
          <Box w="200px">
            <Text fontWeight="medium">{item?.nama_mahasiswa}</Text>
          </Box>
          <Box w="150px">
            <Text fontWeight="medium">{item?.lokasi}</Text>
          </Box>
          <Box w="150px">
            <Text fontWeight="medium">{item?.jenis_kegiatan}</Text>
          </Box>
          <Box w="200px">
          <Popconfirm
               title='Are you sure you want to delete?'
                onOk={
                  async () => {
                      await Axios.delete("admin/deleteBimbinganDpl/"+item?.id_bimbingan)
                      .then(res => {
                        if(res.status === 201){

                          let newBimbingan = [...bimbingan];
                              newBimbingan = newBimbingan.filter(t => t.id_bimbingan !== item?.id_bimbingan);

                              setBimbingan(newBimbingan)

                          toast({
                            title: 'Berhasil',
                            description: `${res.data.message}`,
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                            position: 'top-right',
                          });
                        }
                      }).then(err => console.log(err))
                    }
                }
             >
                <Button status='danger'>Hapus</Button>
             </Popconfirm>
          </Box>
        </HStack>
      ))
    }


    {
      modal === true && (
        <ModalMove bimbingan={bimbingan} setErrosUpdate={setErrosUpdate} modal={modal} setBimbingan={setBimbingan} setModal={setModal} semester={semester} tahun_akademik={tahun_akademik} id_dosen={id_dosen} />
      )
    }

    </>
  )
}

const Header = () => {
  return(
    <HStack w="full" p={2} mt="10px" spacing="10px" fontSize="12px">
      <Box as={Flex} w="250px">
        <Box>
          <Text fontWeight="medium">Nama Mahasiswa</Text>
        </Box>
      </Box>
      <Box w="200px">
        <Text fontWeight="medium">Tempat</Text>
      </Box>
      <Box w="150px">
        <Text fontWeight="medium">No HP</Text>
      </Box>
      <Box w="150px">
        <Text fontWeight="medium">Jenis Kegiatan</Text>
      </Box>
      <Box w="100px">
        <Text fontWeight="bold">Input Nilai</Text>
      </Box>
      <Box>
        <Text fontWeight="bold" ml="15px">Aksi</Text>
      </Box>
    </HStack>
  )
}

const ModalMove = ({modal, setModal, bimbingan, semester, tahun_akademik, id_dosen, setBimbingan, setErrosUpdate}) => {
  let toast = useToast();


  const[mahasiswa, setMahasiswa] = useState([]);
  const[mahasiswaBimbingan, setMahasiswaBimbingan] = useState("");
  const[loading, setLoading] = useState(false);

  useEffect(() => {
    getDataMahasiswa();
  },[]);

  const getDataMahasiswa = async () => {
    setLoading(true)
    await Axios.post("admin/getDataMahasiswa", {semester, tahun_akademik})
    .then(({data}) =>{
      setMahasiswa(data);
      setLoading(false)
    }).catch(error => console.log(error))
  }


  const handleSubmit = async () => {
    
    const formData = {
      id_dosen,
      ids: mahasiswaBimbingan,
      tahun_akademik,
      semester
    }

    await Axios.post('admin/updateBimbingan', formData)
          .then(({data}) => {
            console.log(data.successfully)
              if(data.successfully.length > 0) {
                let newBimbingan = [...bimbingan];

                const itemSuccessInsert = data.successfully;

                itemSuccessInsert.map(item => {
                  newBimbingan.unshift(item);

                })
                setBimbingan(newBimbingan);
                 toast({
                  title: 'Berhasil',
                  description: `Data berhasil di simpan!`,
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                  position: 'top-right',
                });
              }
              setErrosUpdate(data.errors);
              setModal(false)
          })
           .catch(err => {
            toast({
              title: 'Gagal menyimpan',
              description: `Silahkan ulangi lagi!`,
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: 'top-right',
            })
          })
  }


  return(
    <Modal
      title='Tambahkan Bimbingan'
      visible={modal}
      onOk={() => handleSubmit()}
      onCancel={() => setModal(false)}
      autoFocus={false}
      focusLock={true}
    >
    {
      loading && (
        <Text>ambil data...</Text>
      )
    }
    <Select
        placeholder='Pilih pendaftar'
        style={{ width: "100%"}}
        mode='multiple'
        loading={loading}
        onChange={e => setMahasiswaBimbingan(e)}
        disabled={loading}
      >
      {mahasiswa?.map((item, index) => (
         <Option key={index} value={item?.id_pendaftar}>
           {item?.nim}
         </Option>
       ))}
      </Select>
    </Modal>
  )
}
