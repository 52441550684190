
import { useState, useEffect, useRef } from "react";
import { Text, Box, Button, Center, Stack, Input, Flex, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, useToast } from "@chakra-ui/react";
import Axios from "services/AxiosConfig";
import Loader from "helpers/Loader";
import { AiOutlineCloudUpload } from "react-icons/ai";

export default function InputNilai() {

	let toast = useToast(); 

	const[nilai, setNilai] = useState([]);
	const[loading, setLoading] = useState(false);
	const[loadingPost, setLoadingPost] = useState(false);

	useEffect(() => {
		getBimbingan();	
	},[]);

	
	const getBimbingan = async () => {
		setLoading(true)
		await Axios.get("dpl/getDataBimbinganNilai")
				.then(({data}) => {
					setNilai(data.bimbingan);
					setLoading(false)
				})
				.catch(err => {
	            const {status, data: {message}} = err.response;
	              toast({
		             title: `Gagal (${status})!`,
		             description: `${message}`,
		             status: 'error',
		             duration: 5000,
		             isClosable: true,
		           });
					setLoading(false)
	          })
	}


	const handleSubmitResult = async () => {
		setLoadingPost(true)
		await Axios.post('dpl/simpanNilaiResult', {nilai })
				.then(res => {
	  				const {status, data: {message}} = res;
		  			toast({
		                title: 'Sukses!',
		                description: message,
		                status: 'success',
		                duration: 5000,
		                isClosable: true,
		                position: "top"
		              });
					setLoadingPost(false)
				})
				.catch(err => {
		            const {status, data: {message}} = err.response;
		              toast({
			             title: `Gagal (${status})!`,
			             description: `${message}`,
			             status: 'error',
			             duration: 5000,
			             isClosable: true,
			           });
						setLoadingPost(false)
		          })
	}

	const handleChange = (e, index) => {
		const name = e.target.name,
				value = e.target.value;

		const newNilai = [...nilai];
				newNilai[index][name] = value;

		setNilai(newNilai);

		const dataNow = [...nilai][index];

		const resultCount = (Number(dataNow["n_pembekalan"]) + Number(dataNow['n_pelaksanaan']) + Number(dataNow['n_laporan']) + Number(dataNow['n_akhir'])) / 4;


		let grade = '';

		if(resultCount >= 80) {
			grade = 'A'
		} else if (resultCount >= 70) {
			grade = 'B'
		}else if(resultCount >= 60) {
			grade = 'C'
		}else{
			grade = 'D'
		}

		newNilai[index]['grade'] = grade;

		setNilai(newNilai)

	}



	return(
		<>
			<Text fontSize="xl" fontWeight="bold">INPUT NILAI</Text>
			{
				loading ? <Loader/> : (
					<>
						<Box mt={4} bg="#fff" p={4}>
							<Center as={Text} fontWeight="medium">DAFTAR BIMBINGAN ANDA</Center>

							<Box mt={4} w="100%">
								<form >
									<TableContainer>
									  <Table variant='simple' size="sm">
										<TableCaption>
											<Button size="md" isLoading={loadingPost} disabled={loadingPost} leftIcon={<AiOutlineCloudUpload />} colorScheme='whatsapp' borderRadius="sm" onClick={() => handleSubmitResult()}>Simpan</Button>
										</TableCaption>
										<Thead>
										  <Tr >
											<Th>No</Th>
											<Th>Mahasiswa</Th>
											<Th>N. Pembekalan</Th>
											<Th>N. Pelaksanaan</Th>
											<Th>N. Laporan</Th>
											<Th>N. Akhir</Th>
											<Th>Grade</Th>
										  </Tr>
										</Thead>
										<Tbody>
										  
										  {
										  	nilai.map((item, index) => (
										  			<Tr key={index}>
														<Td>{index + 1}</Td>
														<Td>{item.nama_lengkap}</Td>
														<Td>
																<Input name="n_pembekalan" value={item.n_pembekalan} onChange={e => handleChange(e, index)} type="number" variant="filled" size="sm" borderRadius="xs" placeholder='Pembekalan...' />
														</Td>
														 <Td>
																<Input value={item.n_pelaksanaan} name="n_pelaksanaan" onChange={e => handleChange(e, index)} type="number" variant="filled" size="sm" borderRadius="xs" placeholder='Pelaksanaan...' />
														</Td>
														 <Td>
																<Input name="n_laporan" onChange={e => handleChange(e, index)} value={item.n_laporan} type="number" variant="filled" size="sm" borderRadius="xs" placeholder='Laporan...' />
														</Td>
														<Td>
																<Input name="n_akhir" onChange={e => handleChange(e, index)} value={item.n_akhir} type="number" variant="filled" size="sm" borderRadius="xs" placeholder='Akhir...' />
														</Td>
														<Td>
															<Center>{item.grade}</Center>
														</Td>
													 </Tr>
										  	))
										  }
										  
										</Tbody>
									  </Table>
									</TableContainer>
								</form>
							</Box>

						</Box>
					</>
				)
			}
		</>
	);
}