import { Text, Box, Heading, Flex, Button, Container } from "@chakra-ui/react";
import {  ArrowForwardIcon } from '@chakra-ui/icons';
import { Link } from "react-router-dom";

export default function Video() {
	return(
		<Box bg="#fff" mb={"30px"} px={{base:'10px', md: '0'}}>
			<Container maxW="container.xl" bg="#48BB78" borderRadius="xl" >
				<Flex p={{base: 5, md: 9}} flexDir={{base: "column", md: "row"}}>
					<Box color="#fff"  justifyContent="center" alignItems="center" w={{base: "100%", md:"50%"}}>
						<Heading>Video kegiatan Merdeka Belajar Kampus Merdeka FKIP</Heading>
						<Text mt={"10px"} fontSize="lg" fontWeight="normal">Lihat video kegiatan pilihan selama menjalankan MKBM.</Text>
					</Box>
					<Box as={Flex} justifyContent="center" align="center" w={{base: "100%", md:"50%"}}>
						<Button
							as={Link}
							to="/videos"
							_active={{bg: '#2F855A'}}
							mt={{base: '20px', md: '0'}}
							bg="#2F855A" 
							color="#fff" 
							size="lg" 
							borderRadius={"none"} 
							rightIcon={<ArrowForwardIcon />}
							_hover={{color: "#fff", bg: "#276749"}}
							fontSize="medium"
							>
							Lihat Selengkapnya
						</Button>
					</Box>
				</Flex>
			</Container>
		</Box>
	)
}