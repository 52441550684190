
import { useState, useEffect } from "react";
import { Text, Box, Icon, Link, Alert,  AlertIcon, AlertTitle, AlertDescription, Stack, HStack, FormControl, FormLabel, Input, Button, Center, useToast, Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,} from "@chakra-ui/react";

import { FiDownload, FiPlus, FiBookmark, FiTrash, FiEdit } from "react-icons/fi";
import Axios from "services/AxiosConfig";
import Loader from "helpers/Loader"
import { ExternalLinkIcon } from '@chakra-ui/icons'
import baseURL from "services/BaseUrl"


const MhsDocument = () => {

	let toast = useToast();
  	const token = localStorage.getItem("token");

	const [inputFields, setInputFields] = useState([
	    {matakuliah: '', sks: ''}
	]);

	const[showForm, setShowForm] 				= useState(false);
	const[loading, setLoading] 				= useState(false);
	const[loadingGet, setLoadingGet]			= useState(false);
	const[matakuliah, setMakuliah]			= useState([]);
	const[showFormEdit, setShowFormEdit]	= useState(false);
	const[statusDaftar, setStatusDaftar]	= useState(false);

	useEffect(() => {
		getMatakuliah();
	},[]);

	const getMatakuliah = async () => {
		setLoadingGet(true)
		await Axios.get("mahasiswa/getMatakuliah")
				.then(({data}) => {
					setStatusDaftar(data.status)
					setMakuliah(data.data);
					setLoadingGet(false)
					setInputFields(data.data)
				})
				.catch(err => {
		            const {status, data: {message}} = err.response;
		            setLoadingGet(false)
		              toast({
		                title: `Gagal (${status})!`,
		                description: `${message}`,
		                status: 'error',
		                duration: 5000,
		                isClosable: true,
		              })
	          })
	}

	const handleFormChange = (index, event) => {
    	let data = [...inputFields];
    	data[index][event.target.name] = event.target.value;
    	setInputFields(data);
	}

	const addFields = () => {
	    let newfield = { matakuliah: '', sks: '' }

	    setInputFields([...inputFields, newfield])
	}

	const removeFields = (index) => {
	    let data = [...inputFields];
	    data.splice(index, 1)
	    setInputFields(data)
	}
	const submitForm = async () => {
		setLoading(true)
		const result = inputFields.filter(o => Object.values(o).some(v => v !== ""));

		await Axios.post("mahasiswa/simpanMatakuliah", result)
				.then(({data}) => {
					getMatakuliah();
					toast({
		                title: `Berhasil!`,
		                description: `${data.message}`,
		                status: 'success',
		                duration: 5000,
		                isClosable: true,
		              });
					setShowFormEdit(false);
					setLoading(false)
				})
				.catch(err => {
		            const {status, data: {message}} = err.response;
		            setLoading(false)
		              toast({
		                title: `Gagal (${status})!`,
		                description: `${message}`,
		                status: 'error',
		                duration: 5000,
		                isClosable: true,
		              })
	          })
		
	}


	const totalSks = (matakuliah.reduce((total,currentItem) =>  total = total + Number(currentItem.sks) , 0 ));


	return(
		<>
			{
				loadingGet ?  <Loader /> : (

					<Box>
						<Box px={2} py={3} bg="#F5DF99">
			              <Text fontWeight="bold">Dokumen Penting : </Text>
			             </Box>
			             <Box w="full" bg="#fff" p={4}>
			             	<Link as={Text} color="blue.400" onClick={() => setShowForm(!showForm)} href="#">Surat Rekomendasi <Icon as={FiDownload} size="xl" /></Link>
			             </Box>

			             {
			             	showForm && (
			             		<Box w="full" bg="#fff" p={4} mt={5}>
					             	{
					             		matakuliah.length > 0 ? (
					             			<Box>
					             				<Alert status='success' mb={3}>
													   <AlertIcon />
													    Daftar matakuliah anda, yang akan di konversi.
												  </Alert>
					             					<TableContainer>
														  <Table size='sm'>
														    <Thead>
														      <Tr>
														        <Th w="20px">No</Th>
														        <Th>Nama Matakuliah</Th>
														        <Th>sks</Th>
														      </Tr>
														    </Thead>
														    <Tbody>
														      {
														      	matakuliah.map((item, index) => (
														      		<Tr key={index}>
																        <Td>{index + 1}</Td>
																        <Td>{item.matakuliah}</Td>
																        <Td>{item.sks}</Td>
																      </Tr>
																      
														      	))
														      }
														    </Tbody>
														    <Tfoot>
														      <Tr>
														        <Th colspan="2">
														        	<Center>Jumlah</Center>
														        </Th>
														        <Th>
														        	{
														        		totalSks
														        	}
														        </Th>
														      </Tr>
														    </Tfoot>
														  </Table>
														</TableContainer>

														 <Center mt="20px">
										            	{
										            		statusDaftar === false && (
										            			<Button mr={1} onClick={() => setShowFormEdit(!showFormEdit)} size="sm" borderRadius="sm" colorScheme='whatsapp' variant='solid' leftIcon={<FiEdit />} >Ubah</Button>
										            		)
										            	}
										            	<Button as={Link} href={baseURL+'mahasiswa/cetak_surat_rekomendasi/'+token} isExternal size="sm" borderRadius="sm" colorScheme='teal' variant='solid' leftIcon={<FiDownload />}>Download surat rekomendasi</Button>
										            </Center>

					             			</Box>
					             		):(
					             			<>
					             			<Alert status='warning' mb={3}>
												   <AlertIcon />
												    Silahkan isi matakuliah  dan sks  untuk mendownload surat rekomendasi! max 20 sks
											  </Alert>
							             	
											  <Stack spacing={4} w="100%" mt={7} fontSize="12px">
									            {
									            	inputFields.map((input, index) => (
									            		<HStack w="100%">
											              <Box w="70%">
											                <FormControl id="firstName" isRequired>
											                  <FormLabel fontSize="12px">Nama matakuliah</FormLabel>
											                  <Input size="sm" type="text" name="matakuliah" value={input.matakuliah} onChange={event => handleFormChange(index, event)} />
											                </FormControl>
											              </Box>
											              <Box w="15%">
											                <FormControl id="lastName" isRequired>
											                  <FormLabel fontSize="12px">Sks</FormLabel>
											                  <Input size="sm" type="number" name="sks" value={input.sks} onChange={event => handleFormChange(index, event)}/>
											                </FormControl>
											              </Box>
											              <Box w="15%">
											              	{
											              		index === 0 ? '' : (
											              			<Button disabled={loading} onClick={() => removeFields(index)} borderRadius="sm" leftIcon={<FiTrash />} size="sm" colorScheme='red' variant='solid' mt={7}>
																	    Hapus
																	  </Button>
											              		)
											              	}
											              </Box>
											            </HStack>
									            	))
									            }

									            <Box mt={4} fontSize="13px">
												  		<Text>Jumlah Matakuliah : {inputFields.length}</Text>
												  </Box>

									            <Center>
									            	<Button disabled={loading} mr="5px" onClick={() => addFields()} size="sm" borderRadius="sm" colorScheme='whatsapp' variant='solid' leftIcon={<FiPlus />} >Tambah</Button>
									            	<Button disabled={loading} isLoading={loading} onClick={() => submitForm()} size="sm" borderRadius="sm" colorScheme='teal' variant='solid' leftIcon={<FiBookmark />}>Simpan</Button>
									            </Center>
									           </Stack>
					             			</>
					             		)
					             	}
					             </Box>
			             	)
			             }

					</Box>
				)
			}

			{
				showFormEdit && (
					<Box mt="20px" w="100%" bg="#fff" p={4}>
						<Alert status='info' mb={3}>
							   <AlertIcon />
							    Edit data matakuliah!
						  </Alert>
						{
							inputFields.map((input, index) => (
		            		<HStack w="100%">
				              <Box w="70%" my={1}>
				                <FormControl id="firstName" isRequired>
				                  <FormLabel fontSize="12px">Nama matakuliah</FormLabel>
				                  <Input size="sm" type="text" name="matakuliah" value={input.matakuliah} onChange={event => handleFormChange(index, event)} />
				                </FormControl>
				              </Box>
				              <Box w="15%" my={1}>
				                <FormControl id="lastName" isRequired>
				                  <FormLabel fontSize="12px">Sks</FormLabel>
				                  <Input size="sm" type="number" name="sks" value={input.sks} onChange={event => handleFormChange(index, event)}/>
				                </FormControl>
				              </Box>
				              <Box w="15%" my={1}>
				              	{
				              		index === 0 ? '' : (
				              			<Button disabled={loading} onClick={() => removeFields(index)} borderRadius="sm" leftIcon={<FiTrash />} size="sm" colorScheme='red' variant='solid' mt={7}>
										    Hapus
										  </Button>
				              		)
				              	}
				              </Box>

				            </HStack>
		            	))
						}
						<br />
						<Center>
		            	<Button disabled={loading} mr="5px" onClick={() => addFields()} size="sm" borderRadius="sm" colorScheme='whatsapp' variant='solid' leftIcon={<FiPlus />} >Tambah</Button>
		            	<Button disabled={loading} isLoading={loading} onClick={() => submitForm()} size="sm" borderRadius="sm" colorScheme='teal' variant='solid' leftIcon={<FiBookmark />}>Ubah</Button>
		            </Center>
					</Box>
				)						            	
			}
		</>
	)
}

export default MhsDocument;