
import React from "react"
import { useEffect, useState, useRef } from "react";
import Axios from "../../services/AxiosConfig"
import { useNavigate, Link as LinkRouter } from "react-router-dom";

import { Box, Text, Container, Button, HStack, Alert,  Stack, Input, Flex, Radio, RadioGroup,  AlertIcon, AlertTitle, Select, AlertDescription, Icon, List, ListItem, ListIcon, FormControl, FormLabel,Center, Spinner, Link, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, useToast } from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { FaArrowRight, FaSearchLocation, FaSchool, FaFile, FaTimesCircle } from "react-icons/fa";
import { MdSupervisedUserCircle, MdHourglassBottom } from "react-icons/md";


const InfoSekolah = ({data: {sekolah, jml_pendaftar}, mhs: {nama_mahasiswa, nama_prodi, nim, id_mhs}, slug}) => {

   var now = new Date();
    var month = (now.getMonth() + 1);               
    var day = now.getDate();
    if (month < 10) 
        month = "0" + month;
    if (day < 10) 
        day = "0" + day;
    var today = now.getFullYear() + '-' + month + '-' + day;


  const toast = useToast();
  let navigate = useNavigate();


  const inputRef = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);

  const[selectedFile1, setSelectedFile1]        = useState([]);
  const[selectedFile2, setSelectedFile2]        = useState([]);
  const[selectedFile3, setSelectedFile3]        = useState([]);
  const[errorFile, setErrorFile]                = useState(false);
  const[errorFile2, setErrorFile2]              = useState(false);
  const[errorFile3, setErrorFile3]              = useState(false);
  const[tanggalBayar, setTanggalBayar]          = useState("");
  const[ukuranBaju, setUkuranBaju]              = useState("md");
  const[jenisKepesertaan, setJenisKepesertaan]  = useState("");
  const[noWa, setNoWa]                          = useState("");
  const[loading, setLoading]  = useState(false);

  const[modal, setModal]  = useState(false);



  const handleOpenFileInput = () => {
    inputRef.current.click();
  };

  const handleOpenFileInput2 = () => {
    inputRef2.current.click();
  };

  const handleOpenFileInput3 = () => {
    inputRef3.current.click();
  };

  const handleChangeFile1 = (e) => {
    const file = e.target.files[0],
          ext = file.type;

    if(ext !== "application/pdf"){
        return setErrorFile(true)
    }
    setSelectedFile1(file)

  }

const handleChangeFile2 = (e) => {
    const file = e.target.files[0],
          ext = file.type;

    if(ext !== "application/pdf"){
        return setErrorFile2(true)
    }
    setSelectedFile2(file)

  }

  const handleChangeFile3 = (e) => {
    const file = e.target.files[0],
          ext = file.type;

    if(ext !== "application/pdf"){
        return setErrorFile3(true)
    }
    setSelectedFile3(file)

  }

  function bytesToSize(bytes) {
     var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
     if (bytes == 0) return '0 Byte';
     var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
     return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }

  const confirm = () => {
    setModal(true);
  }

  const handleSubmit = async () => {

      setLoading(true);
      const formData = new FormData();

      formData.append("nim", nim);
      formData.append("suratIzin", selectedFile1);
      formData.append("buktiBayar", selectedFile2);
      formData.append("btq", selectedFile3);
      formData.append("tglBayar", tanggalBayar);
      formData.append("lokasi", sekolah.nama_sekolah);
      formData.append("kepesertaan", jenisKepesertaan);
      formData.append("noWA", noWa);
      formData.append("ukuranBaju", ukuranBaju);
      formData.append("kegiatan", slug);


      await Axios.post("mahasiswa/daftar", formData, {headers: {
        "Content-Type" : "multipart/form-data"
      }})
      .then((res) => {
          setLoading(false)
            navigate("/mhs/kegiatan/status")
            toast({
                title: 'Sukses!',
                description: "Anda berhasil melakukan pendaftaran!",
                status: 'success',
                duration: 5000,
                isClosable: true,
              })
      })
      .catch(error => {
        const {status, data: {message}} = error.response;
          setLoading(false)
          toast({
            title: `Gagal (${status})!`,
            description: `${message}`,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
      })

  } 

  return(
    <Flex as={Container}  maxW="container.xl" my={6} flexDir={{ base: 'column', md: "row" }}>

      <Box w={{ base: 'full', md: "40%" }} bg='white' boxShadow="sm" borderRadius="sm" p={3} mx={1} my={2} h="160px">
        <HStack spacing={5}>
          <Icon as={FaSchool} fontSize="3xl" />
          <Box>
            <Text as="b">
              {sekolah.nama_sekolah} 
            </Text>
            <Text fontSize="12px" color="gray.500">
              {sekolah.alamat}
            </Text>

            <List spacing={1} fontSize="13px" mt={2}>
              <ListItem title="kuota" style={{ cursor: "pointer" }}>
                <ListIcon as={MdHourglassBottom} color='green.500' />
                {sekolah.kuota} orang
              </ListItem>
              <ListItem title="pendaftar" style={{ cursor: "pointer" }}>
                <ListIcon as={MdSupervisedUserCircle} color='green.500' />
                 {jml_pendaftar} Orang
              </ListItem>
            </List>

          </Box>
        </HStack>
      </Box>
      <Box w='full' bg='white' boxShadow="sm" borderRadius="sm" p={8} mx={1} my={2}>
        <form onSubmit={e => {
            e.preventDefault();
            alert("ok")
          }}>
        <Stack spacing={4} w="full">

              <HStack>
                <Box w="50%">
                  <FormControl id="firstName">
                    <FormLabel>Nama</FormLabel>
                    <Input type="text" borderRadius="sm" disabled value={nama_mahasiswa}/>
                  </FormControl>
                </Box>
                <Box w="50%">
                  <FormControl id="lastName">
                    <FormLabel>Nim</FormLabel>
                    <Input type="text" borderRadius="sm" disabled value={nim} />
                  </FormControl>
                </Box>
              </HStack>
              <FormControl id="prodi">
                <FormLabel>Program Studi</FormLabel>
                <Input type="text" borderRadius="sm" disabled value={nama_prodi} />
              </FormControl>
              <FormControl id="SuratIzinPA">
                <FormLabel mb="-6x">Surat Rekomendasi PA</FormLabel>
                <Text fontSize={{ base: "12px", md: "13px" }} color="gray.400" mb={1}>Unggah Surat Rekomendasi dari PA kamu dalam format PDF dengan ukuran maksimal 2 MB. <br />
                  Lihat format surat rekomendasi{' '}
                  <Link color='blue.900' as={LinkRouter} to='/mhs/beranda/document'>
                    disini <ExternalLinkIcon mx='2px' />
                  </Link>
                </Text>
                {
                  selectedFile1.length < 1 ? (
                    <>
                      <Box as={Flex} justifyContent="center" alignItems="center" w="full" bg="white" border="1px dashed" h="80px" borderColor={errorFile === true ? "red.600" : "gray.500"}>
                        <Button colorScheme='teal' size='sm' borderRadius="sm" onClick={handleOpenFileInput}>
                          Pilih File
                        </Button>
                        <input ref={inputRef}  style={{ display: "none" }} type="file" 
                        onChange={e => handleChangeFile1(e)} />

                      </Box>
                      {
                        errorFile && <Text mt={1} fontSize="xs" color="red.600">Hanya file pdf saja, silahkan coba lagi!</Text>
                      }
                    </>
                    ) : (
                     <Box w="full" mt={3} as={Flex} alignItems="center" border="1px dashed" borderColor="teal"p={4} justifyContent="space-between">
                      <Box as={Flex}>
                        <Icon as={FaFile} fontSize="2xl" color='teal' />
                         <Box ml={2}  as={Flex} alignItems="center">
                          <Text fontSize="md" >{selectedFile1.name}</Text>
                          <Text fontSize="10px" color="gray.500" ml={2}>({bytesToSize(selectedFile1.size)})</Text>
                        </Box>
                      </Box>
                      <Box justifyContent="center" alignItems="center" as={Flex}>
                        <Icon as={FaTimesCircle} onClick={() => {
                          setSelectedFile1([])
                          setErrorFile(false)
                        }}  fontSize="xl" _hover={{ cursor: "pointer", color: "red.500" }}/>
                      </Box>
                    </Box>
                    )
                }

              </FormControl>


              <FormControl for="pembayaran" isRequired>
                <FormLabel for="pembayaran" mb="-6x">Bukti Pembayaran</FormLabel>
                <Text fontSize={{ base: "12px", md: "13px" }} color="gray.400" mb={1}>Unggah Bukti Pembayaran kamu dalam format PDF dengan ukuran maksimal 2 MB</Text>
                  <Input type="file" name="file" id="pembayaran" style={{display:'none'}} />

                  {
                  selectedFile3.length < 1 ? (
                    <>
                      <Box as={Flex} justifyContent="center" alignItems="center" w="full" bg="white" border="1px dashed" h="80px" borderColor={errorFile2 === true ? "red.600" : "gray.500"}>
                        <Button colorScheme='teal' size='sm' borderRadius="sm" onClick={handleOpenFileInput3}>
                          Pilih File
                        </Button>
                        <input ref={inputRef3}  style={{ display: "none" }} type="file" 
                        onChange={e => handleChangeFile3(e)} />

                      </Box>
                      {
                        errorFile3 && <Text mt={1} fontSize="xs" color="red.600">Hanya file pdf saja, silahkan coba lagi!</Text>
                      }
                    </>
                    ) : (
                     <Box w="full" mt={3} as={Flex} alignItems="center" border="1px dashed" borderColor="teal"p={4} justifyContent="space-between">
                      <Box as={Flex}>
                        <Icon as={FaFile} fontSize="2xl" color='teal' />
                        <Box ml={2}  as={Flex} alignItems="center">
                          <Text fontSize="md" >{selectedFile3.name}</Text>
                          <Text fontSize="10px" color="gray.500" ml={2}>({bytesToSize(selectedFile3.size)})</Text>
                        </Box>
                      </Box>
                      <Box justifyContent="center" alignItems="center" as={Flex}>
                        <Icon as={FaTimesCircle} onClick={() => {
                          setSelectedFile3([])
                          setErrorFile3(false)
                        }}  fontSize="xl" _hover={{ cursor: "pointer", color: "red.500" }}/>
                      </Box>
                    </Box>
                    )
                }

              </FormControl>

              <FormControl for="btq" isRequired>
                <FormLabel for="btq" mb="-6x">Sertifikat BTQ</FormLabel>
                <Text fontSize={{ base: "12px", md: "13px" }} color="gray.400" mb={1}>Unggah Bukti sertifikat BTQ kamu dalam format PDF dengan ukuran maksimal 2 MB</Text>
                  <Input type="file" name="file" id="btq" style={{display:'none'}} />
                  {
                  selectedFile2.length < 1 ? (
                    <>
                      <Box as={Flex} justifyContent="center" alignItems="center" w="full" bg="white" border="1px dashed" h="80px" borderColor={errorFile2 === true ? "red.600" : "gray.500"}>
                        <Button colorScheme='teal' size='sm' borderRadius="sm" onClick={handleOpenFileInput2}>
                          Pilih File
                        </Button>
                        <input ref={inputRef2}  style={{ display: "none" }} type="file" 
                        onChange={e => handleChangeFile2(e)} />

                      </Box>
                      {
                        errorFile2 && <Text mt={1} fontSize="xs" color="red.600">Hanya file pdf saja, silahkan coba lagi!</Text>
                      }
                    </>
                    ) : (
                     <Box w="full" mt={3} as={Flex} alignItems="center" border="1px dashed" borderColor="teal"p={4} justifyContent="space-between">
                      <Box as={Flex}>
                        <Icon as={FaFile} fontSize="2xl" color='teal' />
                        <Box ml={2}  as={Flex} alignItems="center">
                          <Text fontSize="md" >{selectedFile2.name}</Text>
                          <Text fontSize="10px" color="gray.500" ml={2}>({bytesToSize(selectedFile2.size)})</Text>
                        </Box>
                      </Box>
                      <Box justifyContent="center" alignItems="center" as={Flex}>
                        <Icon as={FaTimesCircle} onClick={() => {
                          setSelectedFile2([])
                          setErrorFile2(false)
                        }}  fontSize="xl" _hover={{ cursor: "pointer", color: "red.500" }}/>
                      </Box>
                    </Box>
                    )
                }
               
              </FormControl>


              <FormControl id="SuratIzinPA">
                <FormLabel>Tanggal Bayar </FormLabel>
                <Input borderRadius="sm" type="date" onChange={e => setTanggalBayar(e.target.value)} max={today} />
              </FormControl>
              <FormControl mb={1}>
                <FormLabel>Ukuran Baju</FormLabel>
                  <RadioGroup defaultValue='md' onChange={e => setUkuranBaju(e)}>
                    <Stack spacing={5} direction='row'>
                      <Radio colorScheme='green' value='S'>
                        sm
                      </Radio>
                      <Radio colorScheme='green' value='M'>
                        md
                      </Radio>
                      <Radio colorScheme='green' value='L'>
                        lg
                      </Radio>
                      <Radio colorScheme='green' value='XL'>
                        xl
                      </Radio>
                      <Radio colorScheme='green' value='XXL'>
                        xxl
                      </Radio>
                    </Stack>
                  </RadioGroup>
              </FormControl>

              <FormControl id="SuratIzinPA">
                <FormLabel>Jenis Kepesertaan</FormLabel>
                  <Select placeholder='--Pilih--' borderRadius="sm" onChange={e => setJenisKepesertaan(e.target.value)}>
                    <option value='Perorangan'>Perorangan</option>
                    <option value='Kelompok'>Kelompok</option>
                  </Select>
              </FormControl>
              <FormControl id="SuratIzinPA" borderRadius="sm">
                <FormLabel>No WhatsApp</FormLabel>
                <Input type="number" borderRadius="sm" onChange={e => setNoWa(e.target.value)}  placeholder="Masukkan no wa"/>
              </FormControl>
              <FormControl>
                  <Button
                    colorScheme='teal'
                    variant='solid'
                    size='md'
                    height='48px'
                    width='full'
                    borderRadius="sm"
                    onClick={confirm}
                  >
                    Daftar Sekarang
                  </Button>
              </FormControl>
          </Stack>
        </form>
      </Box>

      <Modal isOpen={modal} onClose={modal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Anda yakin ?</ModalHeader>
          <ModalCloseButton onClick={() => setModal(false)} disabled={loading}/>
          <ModalBody>
            <Text>Silahkan cek kembali data anda dengan benar!</Text>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' mr={3} onClick={() => setModal(false)} disabled={loading}>
              Close
            </Button>
            <Button colorScheme='blue' disabled={loading} isLoading={loading} loadingText='Submitting...' onClick={handleSubmit}>Daftar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Flex>
  )
}

export default InfoSekolah;

