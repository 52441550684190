import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Container,
  Image
} from "@chakra-ui/react";

import { Link as linkTo } from "react-router-dom"

const Links = [
  {
      title: "Beranda",
      url: '/mhs/beranda'
  },
  {
    title: "Program",
    url: '/mhs/program'
  },
  {
    title: "Kegiatanku",
    url: '/mhs/kegiatan'
  },
  {
    title: "Notifikasi",
    url: '/mhs/notifikasi'
  }
];

const NavLink = ({ title, to }) => (
  <Link
    px={2}
    py={1}
    fontWeight="bold"
    rounded={'sm'}
    as={linkTo}
    to={to}
    _focus={{border: "none"}}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    >
    {title}
  </Link>
);


const Navbar = () => {
  const { isOpen } = useDisclosure();

  return (
    <Box bg={useColorModeValue('white', 'gray.900')} px={4} boxShadow="xs" width="full" borderBottom="1px" borderColor="gray.100">
      <Container maxW="container.xl">
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>

          <HStack spacing={8} alignItems={'center'}>
            <Box>
              <Image src="/logo-mbkm-fkip.png" />
            </Box>

          </HStack>
          <Flex alignItems={'center'}>
            <HStack
              as={'nav'}
              spacing={4}
              mr={4}
              display={{ base: 'none', md: 'flex' }}>
              {Links.map((link) => (
                <NavLink key={link.title} to={link.url} title={link.title} />
              ))}
            </HStack>
            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}>
                <Avatar
                  size={'sm'}
                  src=""
                />
              </MenuButton>
              <MenuList>
                <MenuItem>Link 1</MenuItem>
                <MenuItem>Link 2</MenuItem>
                <MenuDivider />
                <MenuItem>Link 3</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Container>

      {isOpen ? (
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as={'nav'} spacing={4}>
            {Links.map((link) => (
              <NavLink key={link}>{link}</NavLink>
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  )
}

export default Navbar
