import React from "react"
import { Button, Container, Box, Text, Accordion, AccordionItem,  AccordionButton, AccordionPanel,  AccordionIcon, OrderedList, ListItem, Center } from "@chakra-ui/react"
import { Link } from "react-router-dom"

const PertanyaanUmum = () => {
  return (
    <Container maxW="container.md" mb="30px">
      <Box mb="40px" align="center">
        <Text fontSize="3xl" as="b">Pertanyaan Umum</Text>
      </Box>

      <Box py="30px" px="20px" bg="#fff" rounded="sm"  boxShadow="md">
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex='1' textAlign='left'>
                  Bagaimana cara daftar di sistem Merdeka Belajar Kampus Merdeka (MBKM) FKIP UM Kendari?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <OrderedList>
                <ListItem>Anda sudah mempunyai akun di website www.fkipumkendari.ac.id</ListItem>
                <ListItem>Silahkan login dengan klik tombol login di navbar</ListItem>
                <ListItem>Masukkan username dan password menggunakan NIM <small >(Bagi yang belum menggati username dan password di akun website www.fkipumkendari.ac.id)</small></ListItem>
              </OrderedList>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex='1' textAlign='left'>
                  Bagaimana cara upload logbook di sistem Merdeka Belajar Kampus Merdeka (MBKM) FKIP UM Kendari
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Bagaimana cara upload tugas akhir di sistem Merdeka Belajar Kampus Merdeka (MBKM) FKIP UM Kendari
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex='1' textAlign='left'>
                  Bagaimana cara registrasi dan membuat akun di aplikasi Merdeka Belajar Kampus Merdeka (MBKM)?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <OrderedList>
                <ListItem>Lorem ipsum dolor sit amet</ListItem>
                <ListItem>Consectetur adipiscing elit</ListItem>
                <ListItem>Integer molestie lorem at massa</ListItem>
                <ListItem>Facilisis in pretium nisl aliquet</ListItem>
              </OrderedList>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex='1' textAlign='left'>
                  Bagaimana cara registrasi dan membuat akun di aplikasi Merdeka Belajar Kampus Merdeka (MBKM)?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <OrderedList>
                <ListItem>Lorem ipsum dolor sit amet</ListItem>
                <ListItem>Consectetur adipiscing elit</ListItem>
                <ListItem>Integer molestie lorem at massa</ListItem>
                <ListItem>Facilisis in pretium nisl aliquet</ListItem>
              </OrderedList>
            </AccordionPanel>
          </AccordionItem>

          </Accordion>

      </Box>
      
    </Container>
  )
}

export default PertanyaanUmum;
