import { Skeleton, Flex, SkeletonCircle, Box, SkeletonText, Stack, Center } from '@chakra-ui/react'

const SkeletonData = () => {
  return(
    <Flex flexDir={{ base:"column", md: "row" }}>

      <Box w={{ base: "full", nd: "314px" }} h="211px" bg="white" p={3} mt={3} mr={2}>
        <Center>
          <SkeletonCircle size='20' />
        </Center>
        <Center my={3}>
          <Skeleton height='15px' w="100px" />
        </Center>
        <center>
          <SkeletonText  w={"200px"} mt='4' noOfLines={1} spacing='4' />
        </center>
        <Center my={3}>
          <Skeleton height='30px' w="100px" />
        </Center>
      </Box>

      <Box w={{ base: "full", md: "780px" }} h="211px" bg="white" p={3} mt={3}>
          <Skeleton height='30px' w="100%" />
          <SkeletonText w={"full"} mt='4' noOfLines={5} spacing='4' />

      </Box>

    </Flex>

  )

}

export default SkeletonData;
