
import { useState } from "react";
import { Text, Box, List, ListItem, ListIcon, OrderedList, UnorderedList, Link, Flex} from "@chakra-ui/react";
import { MdCheckCircle } from "react-icons/md"
import YouTube from 'react-youtube';


const MhsFAQ = () => {

	const[idYoutube, setIdYoutube] = useState(null);

	return(
		<>
			<Box px={2} py={3} bg="#F5DF99">
	      	<Text fontWeight="bold">Frequently Asked Questions (FAQ) : </Text>
	     	</Box>
	     	<Box w="full" bg="#fff" p={5}>
	     		<List spacing={2}>
	     			<ListItem as={Flex} alignItems="center">
				    <ListIcon as={MdCheckCircle} color='green.500' />
				    <Text onClick={() => setIdYoutube("SH1U9RkWHBY")} as={Link}>Bagaimana cara mendapatkan format surat rekomendasi PA ?</Text>
				  </ListItem>
				  <ListItem as={Flex} alignItems="center">
				    <ListIcon as={MdCheckCircle} color='green.500' />
				    <Text onClick={() => setIdYoutube("8grqfp0Nu9Q")} as={Link}> Bagaimana cara daftar program ?</Text>
				  </ListItem>
				  <ListItem as={Flex} alignItems="center">
				    <ListIcon as={MdCheckCircle} color='green.500' />
				    <Text as={Link}>Bagaimana cara upload logbook ?</Text>
				  </ListItem>
				  <ListItem as={Flex} alignItems="center">
				    <ListIcon as={MdCheckCircle} color='green.500' />
				    <Text as={Link}>Bagaimana cara upload laporan ?</Text>
				  </ListItem>
				</List>
	     	</Box>

	     	<Box mt="10px" w="full" p={5} bg="#fff">
	     		{
	     			idYoutube !== null && (
	     				<ViewTutorial idYoutube={idYoutube} />
	     			)
	     		}
	     	</Box>

		</>
	);
}

export default MhsFAQ;

const ViewTutorial = ({idYoutube}) => {

	const opts = {
      height: '350',
      width: '100%',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
      },
    };

	return(
		<>
			<YouTube videoId={idYoutube} opts={opts}  />
		</>
	)
}