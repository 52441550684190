import { useState } from "react"
import { useNavigate } from "react-router-dom"
import Axios from "../services/AxiosConfig"

import { Flex, Box, FormControl, FormLabel, Input, Stack, Button, Heading, useColorModeValue, Select, useToast } from '@chakra-ui/react';
import { Link as LinkRouter } from "react-router-dom";
import { ChevronLeftIcon } from "@chakra-ui/icons"

import Alert from "../components/utils/Alert"

export default function Login() {

  const navigate = useNavigate();
  const toast = useToast();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) => {
    setLoading(true)
    const data = {username, password, role}
      e.preventDefault();

      Axios.post('login', data, {
        headers: {
          'Accept' : 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(({data}) => {
        console.log(data)
        setLoading(false);
          if(data.hak_akses === 'mahasiswa') {

            const user = { "token": data.token, "name": data.user.nama_lengkap, "prodi": data.user.nama_prodi, "nim": data.user.nim }

            localStorage.setItem('token', data.token);
            // window.location.href = 'http://localhost:3000/mhs/beranda';
            window.location.href = 'https://m2dbapi.fkip.umkendari.ac.id/mhs/beranda';

          }else if(data.hak_akses === 'admin') {

            localStorage.setItem('token', data.token);
            // window.location.href = 'http://localhost:3000/admin';
            window.location.href = 'https://m2dbapi.fkip.umkendari.ac.id/admin';

          }else if(data.hak_akses === 'dpl'){

            localStorage.setItem('token', data.token);
            // window.location.href = 'http://localhost:3000/dpl';
            window.location.href = 'https://m2dbapi.fkip.umkendari.ac.id/dpl';

          } 
          else{
            console.log("not working")
            setLoading(false);
          }

      })
      .catch(error => {
          setLoading(false);
          console.log(error)
          const {status, data: {message}} = error.response;
                toast({
                 title: `Gagal (${status})!`,
                 description: `${message}`,
                 status: 'error',
                 duration: 5000,
                 isClosable: true,
               })
          
      });


  }

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={{base: 'xl', md: '4xl'}}>Sign in to your account</Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Button as={LinkRouter} to="/" leftIcon={<ChevronLeftIcon />} variant="link" colorScheme='teal' mb={4}>
            Kembali
          </Button>

          {error && <Alert status="error" message="User not found" setError={setError} />}
          <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
              <FormControl id="username">
                <FormLabel>Username</FormLabel>
                <Input type="text" value={username} onChange={(e) => setUsername(e.target.value)}/>
              </FormControl>
              <FormControl id="password">
                <FormLabel>Password</FormLabel>
                <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
              </FormControl>
              <FormControl id="role" isRequired>
                <FormLabel>Hak Akses</FormLabel>
                  <Select placeholder='--Pilih--' borderRadius="sm" value={role} onChange={(e) => setRole(e.target.value)}>
                    <option value='md2b'>M2DB</option>
                    <option value='mahasiswa'>Mahasiswa</option>
                    <option value='dpl'>Pembimbing</option>
                  </Select>
              </FormControl>
              <Stack spacing={6}>
                <Button
                  isLoading={loading}
                  loadingText='Submitting'
                  type="submit"
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}>
                  Sign in
                </Button>
              </Stack>
          </Stack>
        </form>
        </Box>
      </Stack>
    </Flex>
  );
}
