
import { useState, useEffect } from "react"
import {useNavigate} from "react-router-dom"
import { Text, HStack, Box, Flex } from "@chakra-ui/react"

import Axios from "services/AxiosConfig"

import {  Button, Select } from '@arco-design/web-react';
import { IconSearch } from '@arco-design/web-react/icon';
const Option = Select.Option;

export default function Bimbingan() {

  let navigate = useNavigate();

  const [ tahunAkademik, setTahunAkedemik ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ tAkad, setTAkad ] = useState("");
  const [ semester, setSemester ] = useState("");

  useEffect(() => {
      getTahunAkedmik();
  }, []);

  const getTahunAkedmik = () => {
    setLoading(true)
    Axios.get("admin/getTahunAkademik")
      .then(({data, status}) => {
        setLoading(false)
        if(status=== 200){
          setTahunAkedemik(data)
        }
      })
      .catch( err => {
        setLoading(false)
      });
  }

  const handleClick = () => {
    const arrTahun = tAkad.split('/')
    navigate(`${arrTahun[0]}+${arrTahun[1]}/${semester}`);
  }

    return(
      <>
        <Text as="b" fontSize="lg">Filter Bimbingan</Text>

        <Box w="full" bg="white"  mt={3} p={4} >
          <HStack spacing="15px">
            <Box>
              <Select
                  placeholder='Tahun akademik'
                  style={{ width: 200 }}
                  allowClear
                  onChange={(e) => setTAkad(e)}
                >
                  {
                    tahunAkademik.map((item, index) => (
                      <Option key={index}>{item.tahun_akademik}</Option>
                    ))
                  }
                </Select>
            </Box>
            <Box>
              <Select
                  placeholder='Semester'
                  style={{ width: 200 }}
                  allowClear
                  onChange={(e) => setSemester(e)}
                >
                <Option value="Genap">Genap</Option>
                <Option value="Ganjil">Ganjil</Option>
                </Select>
            </Box>
            <Button type='primary' onClick={handleClick} loading={loading} disabled={loading}  icon={<IconSearch />}>Cari data</Button>
          </HStack>
        </Box>

      </>
    )
}
