
import { Outlet, Link } from "react-router-dom"
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Text,
  Badge,
  Container,
} from '@chakra-ui/react';
import { HiArchive, HiHome, HiClipboardList, HiBell } from "react-icons/hi";

import Navbar  from "../../components/mahasiswa/Nav"
import Footer  from "../../components/mahasiswa/footer"

const indexMhs = () => {
  return (
    <>
    <Navbar />

      <Box bg="#F8F8F8" minH="100vh">
          <Outlet />
      </Box>

    <Footer />


      <Box position="fixed" overflow="hidden" bottom="0" w="100%" boxShadow="sm" bg="gray.500" color="white" d={{base: 'block', md: 'none'}}>
        <Flex justifyContent="space-evenly">
          <MenuBottomMobile icon={<HiHome />} title="Beranda" url="/mhs/beranda" />
          <MenuBottomMobile icon={<HiArchive />} title="Program" url="/mhs/program" />
          <MenuBottomMobile icon={<HiClipboardList />} title="Kegiatanku" url="/mhs/kegiatan" />
          <MenuBottomMobile icon={<HiBell />} title="Notifikasi" url="/mhs/notifikasi" />
        </Flex>
      </Box>
    </>

  )
}

export default indexMhs;


const MenuBottomMobile = ({icon, title, url}) => {
  return(
    <Flex flexDir="column" justifyContent="center" alignItems="center" m={1} _hover={{ cursor: "pointer" }} as={Link} to={url}>
      <Text fontSize="lg" mt={1}>{icon}</Text>
      <Text fontSize="12px">{title}</Text>
    </Flex>
  )
}
