import { Box, Container, Flex, Text, Icon, Button, Stack, Heading, HStack, Table, Thead,Tbody, Tfoot, Tr, Th, Td, TableCaption,  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider, IconButton, Input, Center, Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription } from "@chakra-ui/react";
import { Outlet, Link, useNavigate} from "react-router-dom";


import { FiChevronsRight } from "react-icons/fi";
import {HamburgerIcon, ChevronDownIcon, TriangleUpIcon} from "@chakra-ui/icons"


const Logbook = () => {

  let navigate = useNavigate();

  return(
    <>

    <Container py={2} maxW="1118px">
        <Box bg="white" borderRadius="md" boxShadow="sm">
        <Box py={3} px={5}>
          <Text fontSize="2xl" fontWeight="bold">Aktivitas</Text>
        </Box>
      </Box>
    </Container>

    <Container maxW="1118px"  py={2} as={Flex}>

      <Box w="20%" d={{base: "none", md: "block"}}>
        <Text>Menu</Text>
        <Box h="2px" my={1} bg="teal" w="90%"></Box>

        <Box  py={2} bg="#F5DF99" _hover={{cursor: "pointer",}} w="90%" as={Flex} alignItems="center" onClick={() => navigate("/mhs/logbook")}>
          <Icon mr={1} as={FiChevronsRight} fontSize="md" ml={2} />
          <Text> Logbook </Text>
        </Box>
          <Box py={2} mt={1} _hover={{bg:"#F5DF99", cursor: "pointer",}} w="90%" as={Flex} alignItems="center" onClick={() => navigate("laporan")}>
          <Icon mr={1} as={FiChevronsRight} fontSize="md" />
          <Text> Laporan </Text>
        </Box>
      </Box>

      <Box w={{base: "full", md: "80%"}}>
        <Outlet />
      </Box>
    </Container>

      <MenuMobile />

    </>
  )
}

export default Logbook;

const MenuMobile = () => {

  let navigate = useNavigate();

  return(
    <Box position="fixed" bottom="60px" left="10px" zIndex="999" d={{base: "block", md: "none"}}>
      <Menu>
        <MenuButton
          bg="#06283D"
          color="#fff"
          _focus={{border: "none", bg:"#F5DF99", color: "#06283D"}}
          _active={{border: "none", bg:"#F5DF99", color: "#06283D"}}
          as={IconButton}
          aria-label='Options'
          icon={<TriangleUpIcon />}
          variant='outline'
        />
        <MenuList>
          <MenuItem onClick={() => navigate("/mhs/logbook")}>
            Logbook
          </MenuItem>
          <MenuItem onClick={() => navigate("laporan")}>
            Laporan
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  )
}

