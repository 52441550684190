
import {Outlet} from "react-router-dom"
import { Text, Box, Flex, Avatar, Container } from "@chakra-ui/react"

import NavItem from "components/admin/NavItem"

import { MdHomeFilled, MdSupervisorAccount, MdDns, MdSettings, MdLogout, MdQuestionAnswer } from "react-icons/md";

export default function Dashboard() {
  return(
    <>
      <Flex flexDir="column" bg="#F6F6FB">
        <Flex  bg="#03033F" color="#fff" w="100%" h="60px" boxShadow="sm" pos="fixed" style={{zIndex: 999}}>
          <Flex as={Container} maxW="container.xl" justifyContent="space-between" alignItems="center">
            <Box>
              <Text as="b">MBKM FKIP UMKENDARI</Text>
            </Box>
            <Box>
              <Avatar name="Hasma Nur" size="sm" />
            </Box>
          </Flex>
        </Flex>

        <Flex flexDir="row" as={Container} maxW="container.xl" w="100%" minH="90vh" my={4}>
          <Box flexDir="column" as={Flex} justifyContent="space-between" w="250px" borderRadius="md" h="80vh" overflowY="hidden" pos="fixed" style={{zIndex: 998}} mt="61px">
            <NavItem />
          </Box>
          <Box w="100%" ml="210px" borderRadius="sm" py="20px" px={2} mt="50px">
            <Outlet />
          </Box>
        </Flex>

      </Flex>
    </>
  )
}
