import { useEffect, useState,  } from "react"
import Axios from "../../services/AxiosConfig"
import { Link } from "react-router-dom"
import { Box, Flex, Text, Image, Button, Avatar, Center, Stack, Heading, HStack, Table, Thead,Tbody, Tfoot, Tr, Th, Td, TableCaption } from "@chakra-ui/react";

import SkeletonData from "../../components/utils/SkeletonData"

const StatusPendaftaran = () => {

  // const userLogin = localStorage.getItem('user');
  const {nim} = "21711170";

  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData () {
      setLoading(true)
      const {data} = await Axios.get("/mahasiswa/getStatusPendaftaran/"+nim);
        setUser(data)

        setLoading(false)
    }
    fetchData();
  }, []);

  return(
    <>
    {loading ?  (
      <SkeletonData />
    ):(
      <KegiatanStatus user={user}/>
    )}

    </>
  )
}

export default StatusPendaftaran;

function KegiatanStatus({user}) {
  if (user.status) {
    return <Registered user={user} />
  }else{
    return <NotRegistered />
  }
}

function Registered({user: {dataInfo}}) {
  return(
    <Box as={Flex} my={5} flexDir={{ base: "column", md: "row" }}>
      <Box w={{base: "full", md: "40%"}} p={4} bg="white" mr={4} mb={2}>
        <Center>
          <Avatar bg="teal" size='lg' name='Ardiman' />{' '}
        </Center>
        <Flex flexDir={"column"} justifyContent="center" alignItems="center" my={2}>
          <Text fontWeight="bold" fontSize="xl" style={{ textTransform: "uppercase" }}>{dataInfo?.nama}</Text>
          <Text fontSize="sm" color="gray.500" style={{ textTransform: "capitalize" }}>{ dataInfo?.nama_prodi }</Text>

          <Button colorScheme='teal' borderRadius="sm" mt={3}>Edit data</Button>
        </Flex>
      </Box>
      <Box w="full" bg="#fff" boxShadow="sm">
        <Box as={Flex} bg="teal" w="full" h="40px" alignItems="center" borderRadius="sm">
          <Text ml={6} color="#fff" fontWeight="bold">Informasi Lainnya</Text>
        </Box>

        <Flex flexDir={{ base: "column", md: "row" }} mt={2}>
          <Box w={{ base: "full", md: "50%" }} mx={1} my={1}>
            <Table variant='striped' colorScheme='teal' size="sm">
              <Tr>
              <Th>Sekolah Tujuan </Th>
              <Td> : { dataInfo?.lokasi }</Td>
            </Tr>
            <Tr>
              <Th>Jenis Kegiatan </Th>
              <Td> : { dataInfo?.jenis_kegiatan }</Td>
            </Tr>
            <Tr>
              <Th>Jenis Kepesertaan </Th>
              <Td> : { dataInfo?.jenis_kepesertaan }</Td>
            </Tr>
            <Tr>
              <Th>No Hp </Th>
              <Td> : { dataInfo?.no_hp }</Td>
            </Tr>
            <Tr>
              <Th>Ukuran Baju</Th>
              <Td> : { dataInfo?.ukuran_baju }</Td>
            </Tr>
            </Table>
          </Box>
          <Box w={{ base: "full", md: "50%" }} h="30px" mx={1} my={1}>
            <Table variant='striped' colorScheme='teal' size="sm">
              <Tr>
              <Th>Tahun Akademik / smt </Th>
              <Td> : { dataInfo?.tahun_akademik } / { dataInfo?.semester }</Td>
            </Tr>
            <Tr>
              <Th>Tanggal Bayar </Th>
              <Td> : { dataInfo?.tgl_bayar }</Td>
            </Tr>
            <Tr>
              <Th>File Rekomendasi PA </Th>
              <Td> : </Td>
            </Tr>
            <Tr>
              <Th>File Bukti Bayar</Th>
              <Td> : </Td>
            </Tr>
            <Tr>
              <Th>Tanggal Daftar</Th>
              <Td> : { dataInfo?.created_at }</Td>
            </Tr>
            </Table>
          </Box>
        </Flex>

      </Box>


    </Box>
  )
}



function NotRegistered() {
  return(
    <Flex mt="50px" flexDir="column" w="full" justipy-content="center" alignItems="center">
      <Image src="/empty1.svg" w={{base: "200px", md: "300px"}}/>
      <Text fontSize="lg" fontWeight="bold" mt={3}>Belum melakukan pendaftaran</Text>
      <Text fontSize={{ base: "12px", md:"sm" }}>Progress pendaftaran program Kampus Merdeka akan dapat dilihat di sini</Text>

      <Button
        as={Link}
        to="/mhs/program"
        colorScheme='teal' variant='solid' mt={2}
        size={{base: "sm", md: 'md'}}
        height='48px'
        width='200px'
        borderRadius="sm"
      >
        Daftar Kegiatan
      </Button>
    </Flex>
  )
}
