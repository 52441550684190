
import { useState, useEffect } from "react"
import {useNavigate} from "react-router-dom"
import Axios from "services/AxiosConfig"
import { Text, Box, Select, HStack,Button, Flex } from "@chakra-ui/react"

import { MdSearch } from "react-icons/md";

export default function Peserta() {
  let navigate = useNavigate();

  const [ tahunAkademik, setTahunAkedemik ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ tAkad, setTAkad ] = useState("");
  const [ semester, setSemester ] = useState("");

  useEffect(() => {
      getTahunAkedmik();
  }, [])

  const getTahunAkedmik = () => {
    setLoading(true)
    Axios.get("admin/getTahunAkademik")
      .then(({data, status}) => {
        setLoading(false)
        if(status=== 200){
          setTahunAkedemik(data)
        }
      })
      .catch( err => {
        setLoading(false)
      });
  }

  const getDataPendaftar = () => {
    const arrTahun = tAkad.split('/')
    navigate(`${arrTahun[0]}+${arrTahun[1]}/${semester}`);
  }

  return(
    <>
    <Text fontSize="xl" as="b">Halaman Peserta</Text>
      <Box as={Flex} alignItems="center" bg="white" w="100%" mt={2} h="70px" p={4}>
        <HStack spacing="15px">
          <Box w="200px">
            <Select onChange={(e) => setTAkad(e.target.value)} name="tahun_akademik" size="sm" id='country' borderRadius="sm" _focus={{ boder: "1px" }} placeholder='Tahun akademik'>
              {
                tahunAkademik.map((item, index) => (
                  <option key={index}>{item.tahun_akademik}</option>
                ))
              }
            </Select>
          </Box>
          <Box w="200px">
            <Select size="sm" onChange={(e) => setSemester(e.target.value)} name="tahun_akademik" borderRadius="sm" _focus={{ boder: "1px" }} placeholder='Pilih semester'>
              <option value="Genap">Genap</option>
              <option value="Ganjil">Ganjil</option>
            </Select>
          </Box>
          <Box w="200px">
            <Button onClick={getDataPendaftar} size="sm" isLoading={loading} loadingText="mengambil data.." leftIcon={<MdSearch />} variant='outline' borderRadius="sm" _hover={{ bg: "yellow.100", border: "none" }}>
              Filter data
            </Button>
          </Box>
        </HStack>
      </Box>
      <Box>
      </Box>
    </>
  )
}
