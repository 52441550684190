
import { useState, useEffect } from "react";

import {Flex, Alert, Link, AlertIcon, AlertTitle, AlertDescription, Text, List, ListItem, ListIcon, Box, HStack, OrderedList, Button, Icon, useToast } from '@chakra-ui/react';
import { MdVerified } from "react-icons/md";
import { FcPrint } from "react-icons/fc";
import Axios from "services/AxiosConfig";
import Loader from "helpers/Loader"
import baseURL from "services/BaseUrl"


export default function Profile ()  {

  const toast = useToast();
  const token = localStorage.getItem("token");

  const[infoMhs, setInfoMhs] = useState("");
  const[loading, setLoading] = useState(false)

  useEffect(() => {
    getInfoDaftar();
  },[]);  
  const getInfoDaftar = async () =>{
    setLoading(true)
    await Axios.get("mahasiswa/getInfoDaftar")
          .then(({data}) => {
            setInfoMhs(data.info);
            setLoading(false)
          })
          .catch(err => { 
              const {status, data: {message}} = err.response;
              setLoading(false)
                toast({
                  title: `Gagal (${status})!`,
                  description: `${message}`,
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                })
            })
  }

  return (
    <>
      {
        loading ? <Loader /> : (
          <>
            {
              infoMhs === "" || infoMhs === null ? (
                <Alert
                  d="none"
                  status='warning'
                  variant='subtle'
                  flexDirection='column'
                  alignItems='center'
                  justifyContent='center'
                  textAlign='center'
                  height='200px'
                >
                  <AlertIcon boxSize='40px' mr={0} />
                  <AlertTitle mt={4} mb={1} fontSize='lg'>
                    Belum melakukan pendaftaran!
                  </AlertTitle>
                  <AlertDescription maxWidth='sm'>
                    Silahkan mendaftar terlebih dahulu, untuk melihat profil kegiatan anda.
                  </AlertDescription>
                </Alert>
                ) : (
                  <>
                    <Box px={2} py={3} bg="#F5DF99" as={Flex} justifyContent="space-between" alignItems="center">
              <Text fontWeight="bold">Informasi Pribadi dan Kegiatan :</Text>
              <Button colorScheme='teal' variant='link' size="xs" mr={3}>
                Ubah
              </Button>
            </Box>

            <Box width="full" bg="white" p={4}>
              <InfoPribadi title="Tahun Akademik" value={infoMhs?.tahun_akademik+' - '+infoMhs?.semester} />
              <InfoPribadi title="Dosen Pembimbing" value={infoMhs?.nilaiAkhir?.nama_dosen}/>
              <InfoPribadi title="Jenis Kegiatan" value={infoMhs?.jenis_kegiatan} />
              <InfoPribadi title="Jenis Kepesertaan" value={infoMhs?.jenis_kepesertaan} />
              <InfoPribadi title="Lokasi" value={infoMhs?.lokasi} />
              <InfoPribadi title="No HP" value={infoMhs?.no_hp} />
              <InfoPribadi title="Ukuran Baju" value={infoMhs?.ukuran_baju} />
              <InfoPribadi title="Tanggal Daftar" value={infoMhs?.date_join} />
            </Box>
 
             <Box px={2} py={3} bg="#F5DF99" mt={5} as={Flex} justifyContent="space-between" alignItems="center">
              <Text fontWeight="bold">Tugas Akhir :</Text>
              <Button colorScheme='teal' variant='link' size="xs" mr={3}>
                Ubah
              </Button>
            </Box>

            <Box mb={3} width="full" bg="white" p={4}>
              <Text fontWeight="bold">Laporan</Text>
              <Box w="full" border="1px dashed" p={2} mt={2} mb={5}>
                  <Text as={Link} to="#" color="blue.800">{infoMhs?.laporan}</Text>
              </Box>

              <Text fontWeight="bold">Video kegiatan</Text>
              <Box w="full" border="1px dashed" p={2} mt={2}>
                  <Text as={Link} to="#" color="blue.800">{infoMhs?.link_kegiatan_magang}</Text>
              </Box>
            </Box>

             <Box px={2} py={3} bg="#F5DF99" mt={5} >
              <Text fontWeight="bold">Nilai Akhir :</Text>
            </Box>

            <Box width="full" bg="white" p={3} mb={4}>
              <Flex flexDir={{base: "column", md: "row"}}>
                <Box w={{base: "100%", md: "30%"}}>
                  <Text>Indikator penilaian</Text>
                  <Box>
                    <Text>100 - 80 = A</Text>
                    <Text>79 - 60 = B</Text>
                    <Text>59 - 40 = C</Text>
                    <Text>39 - 0 = D</Text>
                  </Box>
                </Box>
                <Box w={{base: "100%", md: "70%"}}>
                    <Box w="100%" border="1px solid" p={3} as={Flex} justifyContent="center" alignItems="center">
                      <Text fontWeight="bold">NILAI</Text>
                    </Box>
                    <Box>
                      <Box h="100px" w="full" border="1px solid" as={Flex}>
                          <Box w="40%" borderRight="1px solid" p={2}>
                            <Text>Pembekalan</Text>
                            <Text>Pelaksanaan</Text>
                            <Text>Laporan</Text>
                            <Text>Akhir</Text>
                          </Box>
                          <Box w="10%" p={2} as={Flex} justifyContent="center" >
                            <Box>
                              <Text>{infoMhs?.nilaiAkhir?.n_pembekalan}</Text>
                            <Text fontWeight="medium">{infoMhs?.nilaiAkhir?.n_pelaksanaan}</Text>
                            <Text fontWeight="medium">{infoMhs?.nilaiAkhir?.n_pelaksanaan}</Text>
                            <Text fontWeight="medium">{infoMhs?.nilaiAkhir?.n_laporan}</Text>
                            </Box>
                          </Box>
                          <Box w="50%" borderLeft="1px solid" as={Flex} justifyContent="center" alignItems="center">
                            <Text fontWeight="bold" fontSize="5xl" color={
                              infoMhs?.nilaiAkhir?.grade === 'E' || infoMhs?.nilaiAkhir?.grade === 'D' ? 'red.400' : 'green.400' 
                            }>
                              {
                                infoMhs?.nilaiAkhir?.grade
                              }
                            </Text>
                          </Box>
                      </Box>

                      <Box mt={4}>
                        <Text fontSize="xs" color="gray.500" as="i">
                          {
                            infoMhs.nilaiAkhir?.updated_at === null ? "Belum dinilai" : `Dinilai ${infoMhs.nilaiAkhir?.updated_at}, ${infoMhs.nilaiAkhir?.nama_dosen} `
                          }
                        </Text>
                        <br />
                        <HStack spacing="2px">
                          <Icon as={FcPrint} />
                          <Link fontSize="xs" isExternal href={baseURL+'mahasiswa/cetak_nilai/'+token}>Cetak</Link>
                        </HStack>
                      </Box>
                    </Box>
                </Box>
              </Flex>
            </Box>
                  </>
                )
            }

            
          </>
        )  
      }

    </>
  )
}


const InfoPribadi = ({title, value}) => {
  return(
    <HStack my={1}>
      <Text fontWeight="bold" w="200px">{title} </Text> 
      <Text> : {value} </Text> 
    </HStack>
  )
}