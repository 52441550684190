import {
  Box,
  Flex,
  Text,
  Grid,
  GridItem,
  Alert,
  AlertIcon,
  Icon,
  AlertTitle,
  AlertDescription,
  CloseButton, List, ListItem, ListIcon
} from '@chakra-ui/react';
import { FaUserGraduate } from "react-icons/fa"
import { MdCheckCircle } from "react-icons/md";


const Info = () => {
  return (
    <>

    <Box overflow={{base: "scroll", md:"hidden"}}>

      <Grid templateColumns='repeat(4, 1fr)' gap={6}>
        <GridItem w='100%' bg="white" p={1}>
          <Flex m={3} flexDir="column" justifyContent="center" alignItems="center">
            <Icon as={ FaUserGraduate } fontSize="4xl" />
            <Text mt={1} fontWeight="bold" fontSize="sm" align="center">MAGANG</Text>
            <Text fontSize="sm">(40)</Text>
          </Flex>
        </GridItem>
        <GridItem w='100%' bg="white" >
          <Flex m={3} flexDir="column" justifyContent="center" alignItems="center">
            <Icon as={ FaUserGraduate } fontSize="4xl" />
            <Text mt={1} fontWeight="bold" fontSize="sm" align="center">ASISTENSI MENGAJAR</Text>
            <Text fontSize="sm">(45)</Text>
          </Flex>
        </GridItem>
        <GridItem w='100%' bg="white" >
          <Flex m={3} flexDir="column" justifyContent="center" alignItems="center">
            <Icon as={ FaUserGraduate } fontSize="4xl" />
            <Text mt={1} fontWeight="bold" fontSize="sm" align="center">INDEPENDENT STUDY</Text>
            <Text fontSize="sm">(4)</Text>
          </Flex>
        </GridItem>
        <GridItem w='100%' bg="white" >
          <Flex m={3} flexDir="column" justifyContent="center" alignItems="center">
            <Icon as={ FaUserGraduate } fontSize="4xl" />
            <Text mt={1} fontWeight="bold" fontSize="sm" align="center">MENGAJAR DI DESA</Text>
            <Text fontSize="sm">(1)</Text>
          </Flex>
        </GridItem>
      </Grid>
    </Box>

      <Text mt={7} mb={2} fontWeight="bold">Persyaratan mengikuti program MBKM : </Text>
        <List spacing={3} mb={4}  fontSize={{ base: "12px", md: "14px" }}>
          <ListItem>
            <ListIcon as={MdCheckCircle} color='green.500' />
            Mahasiswa aktif minimal semester 5 dan 7 (semester ganjil)
          </ListItem>
          <ListItem>
            <ListIcon as={MdCheckCircle} color='green.500' />
            Memiliki IPK minimal 3.00 (dibuktikan dengan transkrip nilai sementara)
          </ListItem>
          <ListItem>
            <ListIcon as={MdCheckCircle} color='green.500' />
            Memenuhi kewajiban keuangan di Universitas
          </ListItem>
          <ListItem>
            <ListIcon as={MdCheckCircle} color='green.500' />
            Mahasiswa mengupload berkas pendaftaran pada link unit.fkipumkendari.ac.id (Rekomendasi PA, dan bukti pembayaran)
          </ListItem>
        </List>

        <Text mt={7} mb={2} fontWeight="bold">Biaya :</Text>
          <List spacing={3} mb={4} fontSize={{ base: "12px", md: "14px" }}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color='green.500' />
              Asistensi mengajar dan magang Rp. 1.500.000
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color='green.500' />
              Mengajar didesa/membangun desa Rp. 1.800.000
            </ListItem>
            <ListItem>
              <ListIcon as={MdCheckCircle} color='green.500' />
              Indepedent study Rp. 600.000
            </ListItem>
          </List>


    </>
  )
}

export default Info;
