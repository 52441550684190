
import { useState, useEffect } from "react";
import Axios from "services/AxiosConfig";
import { Text, HStack, Box, FormControl, FormLabel, Button, Input, FormErrorMessage, FormHelperText, Select, Textarea,  Alert, AlertIcon, Center, useToast  } from "@chakra-ui/react";


const Pengaturan = () => {

	let toast = useToast();
 
	const[pengaturan, setPengaturan] = useState([]);
	const[loading, setLoading] = useState(false)

	useEffect(() => {
		getDataPengaturan();
	}, []);

	const getDataPengaturan = async () => {
		const data = await Axios.get('admin/getDataPengaturan')
							.then(({data}) => {
								setPengaturan(data.pengaturan)
							})
							.catch(err => console.log(err))
	}

	const handleChange = e => {
		const name = e.target.name,
				value = e.target.value;
		setPengaturan({...pengaturan, [name]: value})
	}


	const handleSubmit = async () => {
		setLoading(true)

		await Axios.post("admin/simpanPengaturan", {pengaturan})
				.then(res => {
					const {status, data: {message}} = res;
					toast({
		                title: 'Sukses!',
		                description: message,
		                status: 'success',
		                duration: 5000,
		                isClosable: true,
		                position: "bottom"
		              });
					setLoading(false)
				})
				.catch(err => {
					console.log(err)
					setLoading(false)
				})
	}

	return (
		<>
			<Text fontSize="xl" as="b">Pengaturan</Text>

			{/*<Alert my="15px" status='success' variant='subtle'>
		    <AlertIcon />
		    	<Box m={2}>
		    		Pendaftaran tahun akademik 2022/Ganjil telah dibuka!
		    	</Box>
		  </Alert>*/}

			<Box w={"100%"} bg={"#fff"} mt="15px" p={6}>

				<form>
					<HStack>
						<FormControl>
						  <FormLabel fontSize="sm">Tahun Akademik</FormLabel>
						  <Input type='text' value={pengaturan?.tahun_akademik} name="tahun_akademik" onChange={e => handleChange(e)}  borderRadius="sm" placeholder="masukkan tahun akademik..." size="sm" />
						</FormControl>
						<FormControl>
						  <FormLabel fontSize="sm">Semester</FormLabel>
						  <Select placeholder='pilih semester' size="sm" value={pengaturan?.semester} name="semester" onChange={e => handleChange(e)}>
							  <option value='Ganjil' selected={pengaturan?.semester == "Ganjil"}>Ganjil</option>
							  <option value='Genap' selected={pengaturan?.semester == "Genap"}>Genap</option>
							</Select>
						</FormControl>
					</HStack>
					<HStack mt="20px">
						<FormControl>
						  <FormLabel fontSize="sm">Tanggal mulai pendaftaran</FormLabel>
						  <Input type='date' borderRadius="sm" size="sm" value={pengaturan?.tgl_mulai} name="tgl_mulai" onChange={e => handleChange(e)}/>
						</FormControl>
						<FormControl>
						  <FormLabel fontSize="sm">Tanggal berakhir pendaftaran</FormLabel>
						  <Input type='date' borderRadius="sm" size="sm" value={pengaturan?.tgl_berakhir} name="tgl_berakhir" onChange={e => handleChange(e)} />
						</FormControl>
					</HStack>
					<HStack mt="20px">
						<FormControl>
						  <FormLabel fontSize="sm">Tanggal Pembekalan</FormLabel>
						  <Input type='date' borderRadius="sm" size="sm" value={pengaturan?.tgl_pembekalan} name="tgl_pembekalan" onChange={e => handleChange(e)}/>
						</FormControl>
						<FormControl>
						  <FormLabel fontSize="sm">Tanggal Penarikan</FormLabel>
						  <Input type='date' borderRadius="sm" size="sm" value={pengaturan?.tgl_penarikan} name="tgl_penarikan" onChange={e => handleChange(e)}/>
						</FormControl>
					</HStack>

					<FormControl mt="15px">
					  <FormLabel fontSize="sm">Deskripsi <small>(Optional)</small></FormLabel>
					  <Textarea placeholder='Masukkan deskripsi kegiatan...' borderRadius="sm" size="sm" onChange={e => handleChange(e)} value={pengaturan?.ket} name="ket" />
					</FormControl>
					<br />

					<Center>

					<Button disabled={loading} isLoading={loading} colorScheme='teal' variant='solid' borderRadius="sm" size="md" onClick={() => handleSubmit()}>
				    Simpan pengaturan
				  </Button>
						
					</Center>					
				</form>
					
			</Box>
		</>
	)
}

export default Pengaturan;