
import { useNavigate } from "react-router-dom"
import Axios from "services/AxiosConfig"

import { HStack, Box, Icon, Text, Flex, List, ListItem, ListIcon } from "@chakra-ui/react"
import { MdOutlinePersonOutline, MdCheckCircle, MdHideSource} from "react-icons/md";
import { useToast} from "@chakra-ui/react"

import { Dropdown, Button, Popconfirm, Tooltip, Space } from '@arco-design/web-react';
import { IconDelete, IconEdit } from '@arco-design/web-react/icon';

const ButtonGroup = Button.Group;




export default function ListPembimbing({data, semester, tahunAkademik, getDataBimbingan}) {

  const toast = useToast();
  let navigate = useNavigate();

  return(
    <>
      <HStack bg="white" w="full" p={2} mt={2} spacing="10px" fontSize="12px">
        <Box as={Flex} w="250px">
          <Icon as={MdOutlinePersonOutline} fontSize="30px" mr={3} />
          <Box>
            <Text>{data.nama_dosen}</Text>
            <Text color="gray.500">Pendidikan Bahasa Inggris</Text>
          </Box>
        </Box>
        <Box w="200px">
          <Text fontWeight="medium">{data.info2.jmlBimbingan} Orang</Text>
        </Box>
        <Box w="150px">
          <Text fontWeight="medium">
            {
              data.info2.sekolah.map(data => (
                <Text>{data.lokasi }</Text>
              ))
            }
          </Text>
        </Box>
        <Box w="150px">
          <Text fontWeight="medium">
            {
              data.info2.sekolah.map(data => (
                <Text>{data.jenis_kegiatan }</Text>
              ))
            }
          </Text>
        </Box>
        <Box w="100px">
          <List spacing={0}>
            <ListItem>
              <ListIcon as={MdCheckCircle} color='green.500' />
              2 orang
            </ListItem>
            <ListItem>
              <ListIcon as={MdHideSource} color='red.500' />
              1 orang
            </ListItem>
          </List>

        </Box>
        <Box>
        <Space size='xs'>
            <Popconfirm
               title='Are you sure you want to delete?'
                onOk={
                  async () => {
                      await Axios.delete("admin/deleteBimbingan", {
                        data: {id_dosen : data.id_dosen, tahunAkademik, semester}
                      })
                      .then(res => {
                        if(res.status === 200){
                          getDataBimbingan();
                          toast({
                            title: 'Berhasil',
                            description: `${res.data.message}`,
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                            position: 'top-right',
                          });
                        }
                      }).then(err => console.log(err))
                    }
                }
             >
              <Tooltip content='Hapus' position='left'>
                <Button icon={<IconDelete />} status="danger" />
              </Tooltip>
             </Popconfirm>
             <Tooltip content='Ubah' position='left'>
               <Button icon={<IconEdit />} onClick={() => {
                   navigate(`${data.id_dosen}/edit`)
                 }} />
             </Tooltip>
            </Space>
        </Box>
      </HStack>
    </>
  )
}
