
import { useState, useEffect } from 'react';
import Axios from 'services/AxiosConfig';
import { Text, Box, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableContainer, Button,  Modal, ModalOverlay, ModalContent, 
  ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Select, Textarea, useToast } from "@chakra-ui/react";
import { BiPlus } from "react-icons/bi";
import { useForm } from "react-hook-form";

const Sekolah = () => {

	let toast = useToast();

	const[sekolah, setSekolah] 			= useState([]);
	const[loading, setLoading] 			= useState(false);
	const[modalAdd, setModalAdd]			= useState(false);
	const[modalDelete, setModalDelete]	= useState(false);
	const[idSekolah, setIdSekolah]		= useState(null);
	const[loadingDelete, setLoadingDelete]		= useState(false);

	useEffect(() => {
		getDataSekolah();
	}, []);

	const getDataSekolah = async () => {
		setLoading(true)
		await Axios.get('admin/getDataSekolah')
				.then(({data}) => {
					setSekolah(data.sekolah);					
					setLoading(false)
				})
				.catch(err => {
					console.log(err)
					setLoading(false)
				})
	}

	const handleDelete = (id) => {
		setModalDelete(true);
		setIdSekolah(id);
	}

	const deleteAction = async () => {
		setLoadingDelete(true)
		await Axios.delete('admin/deleteSekolah/'+idSekolah)
				.then(res => {
					const {status, data: {message}} = res;
					toast({
	                title: 'Sukses!',
	                description: message,
	                status: 'success',
	                duration: 5000,
	                isClosable: true,
	                position: "bottom"
	              });
					const newDatas = sekolah.filter(t => t.id !== idSekolah);
					setSekolah(newDatas);
					setModalDelete(false);
					setIdSekolah(null);
					setLoadingDelete(false)
				})
				.catch(err => {
					setLoadingDelete(false)
				})
	}

	const handleEdit = (id) => {
		console.log(id)
	}

	return(
		<>
			 <Text as="b" fontSize="lg">Data sekolah</Text>
			 <br />
			 <Button onClick={() => setModalAdd(true)} mt={4} colorScheme='teal' variant='solid' size="sm" borderRadius="sm" leftIcon={<BiPlus />}>Tambah</Button>

			 <Box w="100%" p={4} bg="#fff" mt="10px">
				  <Table variant='simple' size="sm">
				    <Thead>
				      <Tr>
				        <Th>No</Th>
				        <Th>Sekolah</Th>
				        <Th>Alamat</Th>
				        <Th>Kuota</Th>
				        <Th>Status</Th>
				        <Th w="150px">Aksi</Th>
				      </Tr>
				    </Thead>
				    <Tbody>
				      {
				      	sekolah.map((item, i) => (
				      		<Tr key={item.id}>
						        <Td>{i+1}</Td>
						        <Td >{item.nama_sekolah}</Td>
						        <Td>{item.alamat}</Td>
						        <Td >{item.kuota}</Td>
						        <Td><Text color="green">Dibuka</Text></Td>
						        <Td>
						        		<Button colorScheme='teal' variant='solid' size="xs" borderRadius="sm" onClick={() => handleEdit(item.id)}>Ubah</Button>
						        		<Button ml={1} colorScheme='red' variant='solid' size="xs" borderRadius="sm" onClick={() => handleDelete(item.id)}>Hapus</Button>
						        </Td>
						      </Tr>
				      	))
				      }
				    </Tbody>
				  </Table>

				  <ModalAdd setModalAdd={setModalAdd} modalAdd={modalAdd} setSekolah={setSekolah} sekolah={sekolah} />


				  <Modal isOpen={modalDelete} onClose={() => setModalDelete(false)}>
			        <ModalOverlay />
			        <ModalContent>
			          <ModalHeader>Hapus data</ModalHeader>
			          <ModalCloseButton />
			          <ModalBody>
			            <Text>Apakah anda yakin ? data akan dihapus!</Text>
			          </ModalBody>

			          <ModalFooter>
		            	<Button size="sm" variant='red' disabled={loadingDelete} colorScheme="teal" borderRadius="sm" onClick={() => setModalDelete(false)}>Tutup</Button>
			            <Button size="sm" colorScheme='red' disabled={loadingDelete} isLoading={loadingDelete} ml={3} borderRadius="sm" onClick={() => deleteAction()}>Hapus</Button>
			          </ModalFooter>
			        </ModalContent>
			      </Modal>

			 </Box>
		</>
	)
}

export default Sekolah;

const ModalAdd = ({modalAdd, setModalAdd, setSekolah, sekolah}) => {
	let toast = useToast();

	const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
	const[loadingPost, setLoadingPost] = useState(false);

	const onSubmit = async (data) => {
		setLoadingPost(true)
		await Axios.post('admin/simpanSekolah', {form: data})
				.then(res => {
					const {status, data: {message}} = res;
					toast({
	                title: 'Sukses!',
	                description: message,
	                status: 'success',
	                duration: 5000,
	                isClosable: true,
	                position: "bottom"
	              });

					const newDatas = [...sekolah];
							newDatas.unshift(data);
					setSekolah(newDatas)
	            setModalAdd(false)				
					reset();
					setLoadingPost(false)
				})
				.catch(error => {
					const {status, data: {message}} = error.response;
					 toast({
		            title: `Gagal!`,
		            description: `${message}`,
		            status: 'error',
		            duration: 5000,
		            isClosable: true,
		            position: "bottom"
		          })
					setLoadingPost(false)
				})
	}

	return(
		<>
			<Modal isOpen={modalAdd} onClose={modalAdd} size="xl">
	        <ModalOverlay />
	        <ModalContent>
	          <form onSubmit={handleSubmit(onSubmit)}>
	          	<ModalHeader>Tambah sekolah</ModalHeader>
		          <ModalBody>

		          <FormControl isRequired my={2}>
					  <FormLabel fontSize="sm">Nama sekolah</FormLabel>
					  <Input textTransform="uppercase" placeholder='Masukkan nama sekolah...' {...register('nama_sekolah', { required: true})} borderRadius="xs" size="sm" />
					  	
					   {errors.nama_sekolah?.type === 'required' && (
					   	<FormHelperText color="red" fontSize="xs">Nama sekolah harus di isi!</FormHelperText>
					   )}
					</FormControl>

					 <FormControl mt={4}>
					  <FormLabel fontSize="sm">Alamat</FormLabel>
					  <Textarea placeholder='Masukkan alamat sekolah...' {...register('alamat')} borderRadius="sm" size="sm" />
					</FormControl>

					 <FormControl isRequired mt={4}>
					  <FormLabel fontSize="sm">Kuota</FormLabel>
					  <Input placeholder='Masukkan kuota...' type="number" {...register('kuota', {required:true, maxLength:2})} borderRadius="xs" size="sm" />
					  {errors.kuota?.type === 'required' && (
					   	<FormHelperText color="red" fontSize="xs">Nama sekolah harus di isi!</FormHelperText>
					   )}
					  {errors.kuota?.type === 'maxLength' && (
					   	<FormHelperText color="red" fontSize="xs">Hanya bisa 2 angka!</FormHelperText>
					   )}
					</FormControl>

					<FormControl isRequired mt={4}>
					  <FormLabel fontSize="sm">Status</FormLabel>
					  <Select placeholder='Pilih status' size="sm" borderRadius="sm" {...register('status', {required: true})}>
						  <option value='1'>aktif</option>
						  <option value='0'>Tidak Aktif</option>
						</Select>
						 {errors.status?.type === 'required' && (
					   	<FormHelperText color="red" fontSize="xs">Silahkan pilih status!</FormHelperText>
					   )}
					</FormControl>
		          
		          </ModalBody>

		          <ModalFooter>
		            <Button size="sm" disabled={loadingPost} colorScheme='red' mr={3} borderRadius="sm" onClick={() => setModalAdd(false)}>
		              Tutup
		            </Button>
		            <Button size="sm" disabled={loadingPost} isLoading={loadingPost} type="submit" variant='solid' colorScheme="teal" borderRadius="sm">Simpan data</Button>
		          </ModalFooter>
	          </form>
	        </ModalContent>
	      </Modal>
		</>
	);
}