
import { useNavigate } from "react-router-dom";
import Axios from "services/AxiosConfig";
import { useState, useEffect } from "react";
import Loader from "helpers/Loader";

import { Text, Box, Flex, Stack, Select, Button, Center, Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableContainer, useToast } from "@chakra-ui/react";

import { FaEye } from "react-icons/fa";

const Bimbingan = () => {

	let navigate = useNavigate();
	let toast = useToast();

	const[bimbingan, setBimbingan] = useState([]);
	const[tahunAkademik, setTahunAkedemik] = useState([]);
	const[loading, setLoading] = useState(false);

	useEffect(() => {
		getDataBimbingan();
	}, []);
   
	const getDataBimbingan = async () => {
		setLoading(true)
		await Axios.get("dpl/getDataBimbingan")
					.then(({data}) => {
						setBimbingan(data.bimbingan)
						setTahunAkedemik(data.tahun_akademik)
						setLoading(false)
					})
					.catch(err => {
	            const {status, data: {message}} = err.response;
	            setLoading(false)
	              toast({
	                title: `Gagal (${status})!`,
	                description: `${message}`,
	                status: 'error',
	                duration: 5000,
	                isClosable: true,
	              })
	          })
	}
  
	return( 
		<>
			{
				loading ? <Loader /> : (
					<>
						<Stack direction={['column', 'row']} spacing='10px' bg="#fff" w="100%" p={4}>
						  <Box w="30%">
						    <Select placeholder='Tahun akademik' size="sm" borderRadius="sm">
							  <option value='option1'>Option 1</option>
							  <option value='option2'>Option 2</option>
							  <option value='option3'>Option 3</option>
							</Select>
						  </Box>
						  <Box w="20%">
						     <Select placeholder='Semester' size="sm" borderRadius="sm">
								  <option value='Ganjil'>Ganjil</option>
								  <option value='Genap'>Genap</option>
								</Select>
						  </Box>
						  <Box>
						    <Button colorScheme='blue' borderRadius="sm" size="sm">Submit</Button>
						  </Box>
						</Stack>

						<Box bg="#fff" p={4} w="full" mt="20px">
							<Center>
								<Text borderBottom="2px solid #c7c5c1">{tahunAkademik}</Text>
							</Center>

							<Button mt={3} onClick={() => navigate("/dpl/inputNilai")} colorScheme='blue' borderRadius="sm" size="sm">Input Nilai </Button>

							<TableContainer mt={5}>
							  <Table variant='simple' size="sm">
							    <Thead>
							      <Tr>
							        <Th>No</Th>
							        <Th>NIM</Th>
							        <Th>MAHASISWA</Th>
							        <Th>SEKOLAH</Th>
							        <Th>PROGRAM</Th>
							        <Th>AKSI</Th>
							      </Tr>
							    </Thead>
							    <Tbody>
							       
							      {
							      	bimbingan.length > 0 ? (
							      		<>
							      		{
							      			bimbingan.map((item, index) => (
							      				<Tr key={index}>
											        <Td>{index + 1}</Td>
											        <Td>{item?.nim}</Td>
											        <Td>{item?.nama_mahasiswa}</Td>
											        <Td>{item?.lokasi}</Td>
											        <Td>{item?.jenis_kegiatan}</Td>
											        <Td>
											        		<Button onClick={() => navigate(`aktivitas/${item.id}`)} size="xs" borderRadius="sm" leftIcon={<FaEye />} colorScheme='teal' variant='solid'>
														    Aktivitas
														  </Button>
											        </Td>
											      </Tr>
							      			))
							      		}
							      		</>
							      	) : (
							      		<Tr>
							      			<Td colspan="6">
							      				<Center><Text fontWeight="bold">Tidak ada data</Text></Center>
							      			</Td>
							      		</Tr>
							      	)
							      }
							      
							    </Tbody>
							  </Table>
							</TableContainer>

						</Box>
					</>
				)
			}

		</>
	)
}

export default Bimbingan;