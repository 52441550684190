import React from "react";
import { useNavigate, Outlet } from "react-router-dom"
import { Text, Box, HStack, Flex, Container } from "@chakra-ui/react";

import "@arco-design/web-react/dist/css/arco.css";

import { Menu } from '@arco-design/web-react';
import { IconApps, IconUserGroup, IconExport, IconQuestion} from '@arco-design/web-react/icon';

const MenuItem = Menu.Item;

const Dashboard = () => {

	let navigate = useNavigate();

	const handleLogout = () => {
		localStorage.removeItem("token");
		navigate("/login")
	}

	return(
		<Box bg="#F6F8FA" minH="98vh">
			<Box bg="blue.600" w="100%" h="60px" pos="fixed" style={{zIndex: 999}}>
				<Box as={Container} w="100%" maxW="container.xl">
					hello
				</Box>
			</Box>
			<Container maxW="container.xl">
				<Box as={Flex} pt="80px">
					<Box pos="fixed" style={{zIndex: 998}} w="250px" overflowY="hidden">
						<div
					      className='menu-demo'
					      style={{
					        width: "100%",
					        height:"100%"
					      }}
					    >

					      <Menu
					        style={{ width: "100%", height: '100%' }}
					      >
					      <MenuItem key='45' onClick={() => navigate('/dpl')}>
					        <IconApps />
					        Dashboard
					      </MenuItem>
					       <MenuItem key='46' onClick={() => navigate('/dpl/bimbingan')}>
					        <IconUserGroup />
					        	Bimbingan
					      </MenuItem>
					      <MenuItem key='47' onClick={() => navigate('/dpl/faq')}>
					        <IconQuestion />
					        	FAQ
					      </MenuItem>
					      <MenuItem key='48' onClick={() => handleLogout()}>
					        <IconExport />
					        	Keluar
					      </MenuItem>
					      </Menu>
					    </div>
					</Box>
					<Box width="full" ml="260px" px={4}>
						<Outlet />
					</Box>
				</Box>
			</Container>
		</Box>
	)
}

export default Dashboard;