

import { useEffect, useState,  } from "react"
import Axios from "../../services/AxiosConfig"
import { Link } from "react-router-dom";
import { Text, Box, Container, Flex, Icon, Wrap, WrapItem, Center, Spinner } from "@chakra-ui/react";
import { FaAngleRight } from "react-icons/fa";

const Program = () => {

  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect( async () => {
    setLoading(true)
    await Axios.get(
       'mahasiswa/program',
     ).then(({data}) => {
       setPrograms(data.programs);
     }).catch(error => {
       console.log(error)
     });

     setLoading(false)

 }, []);

  return (
    <>
      <Box bg={{base: "", md: "#EDEDED"}}>
          <Box  as={Container} maxW="container.xl" py={5}>
             <Box m={{base: 0, md: 8}}>
               <Text fontSize={{base: "2xl", md: '5xl'}} fontWeight="bold">Program Kampus Merdeka</Text>
               <Text mt={{base: 0, md: "-5px"}} fontSize={{base: "13px", md: 'md'}}>Kamu bisa berbagai pilihan program dari <strong>kampus merdeka</strong> yang tersedia untukmu <strong>di FKIP UM Kendari</strong> </Text>
             </Box>
           </Box>
      </Box>
      <Flex flexDir="column" as={Container} maxW="container.xl" py={5} justifyContent="center" alignItems={{ base: "left", md: "center" }}>
         <Text fontSize={{base: "xl", md: '2xl'}} fontWeight="bold"  my={{base: 3, md: 6}}>Program yang dibuka</Text>

           <Box mb={2}>
             {loading && (
               <Center><Spinner size='sm' /></Center>
             )}
           </Box>

          <Flex as={Wrap} flexDir={{ base: "column", md: "row" }} >
            {programs.map(program => (
              <Box as={Link} to={'/mhs/program/'+program.slug}  m={{ base: 0, md: 2 }} my={{ base: 1, md:0 }} w={{ base:"full", md: "360px" }} p={4} borderRadius="sm" boxShadow="md" border="1px" borderColor="gray.100" bg="white" _hover={{ cursor: 'pointer', bg:"blue.50", border: '1px', borderColor: 'blue.200' }}>
                <Flex justifyContent="space-between" alignItems="center"mb={1} >
                  <Text fontWeight="bold">{program.nama_kegiatan}</Text>
                  <Icon as={FaAngleRight} fontWeight="bold" color="blue.400" />
                </Flex>
                <Text fontSize="sm" color="gray.500">{program.deskripsi}</Text>
              </Box>
            ))}

          </Flex>

       </Flex>



    </>
  )
}

export default Program
