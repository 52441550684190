import {
  Box,
  chakra,
  Container,
  Link,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
  Image,
  Center,
  Flex
} from '@chakra-ui/react';
import { FaInstagram, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { ReactNode } from 'react';


export default function SmallWithLogoLeft() {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Flex justifyContent="center" alignItems="center"  py={6} px={{base: '20px', md: 0}}>
        <Text>© Copyright 2022 by Fakultas Keguruan dan Ilmu Pendidikan, UM KENDARI</Text>
      </Flex>
    </Box>
  );
}