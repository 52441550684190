import { Link } from "react-router-dom"
import { Box, Flex, Text, Image, Button, Icon, Center, HStack } from "@chakra-ui/react";
import { useEffect, useState,  } from "react"
import Axios from "../../services/AxiosConfig"
import SkeletonKegiatanAktif from "../../components/utils/SkeletonKegiatanAktif"
import { FaAngleRight, FaSchool } from "react-icons/fa";

const Kegiatanku = () => {

  const [user, setUser]       = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []); 

   const fetchData = async () => {
      setLoading(true)
      await Axios.get("/mahasiswa/getStatusPendaftaran")
            .then(({data}) => {
              console.log(data) 
              setUser(data)
            })
      setLoading(false)
    }

  return(
    <>
    {loading ?  (
      <SkeletonKegiatanAktif />
      ):(
        <KegiatanStatus user={user}/>
    )}
      </>
  )
}

export default Kegiatanku;

function KegiatanStatus({user}) {
  if (user.status) {
    return <Registered user={user} />
  }else{
    return <NotRegistered />
  }
}

function Registered({user: {dataInfo}}) {
  return(
    <>
      <Center> <Text fontSize={{base: "xl", md: 'xl'}} fontWeight="bold"  mt={4}>Kegiatan aktif anda</Text></Center>
      <Flex flexDir={{ base: "column", md: "row" }} >
          <Box as={Link} to="/mhs/logbook" bg="white" w={{ base:"full", md: "360px" }} my={{ base: 2, md:4 }} p={4} borderRadius="sm" boxShadow="sm" border="1px" borderColor="gray.100" bg="white" _hover={{ cursor: 'pointer', bg:"blue.50", border: '1px', borderColor: 'blue.200' }}>
            <Flex justifyContent="space-between" alignItems="center" mb={4} >
              <Text fontWeight="bold">{dataInfo?.jenis_kegiatan}</Text>
              <Icon as={FaAngleRight} fontWeight="bold" color="blue.400" />
            </Flex>
            <HStack mt={2} spacing='25px'>
              <Icon as={FaSchool} h="30px" w="30px"  />
              <Box>
                <Text fontSize="14px" style={{textTransform: "capitalize"}}>{dataInfo?.lokasi}</Text>
                <Text fontSize="12px" color="gray.500">{dataInfo?.tahun_akademik} / {dataInfo?.semester}</Text>
                <Text fontSize="12px" color="gray.500">{dataInfo?.jenis_kepesertaan}</Text>
              </Box>
            </HStack>
          </Box>
      </Flex>
    </>
  )
}

function NotRegistered() {
  return(
    <Flex mt="50px" flexDir="column" w="full" justipy-content="center" alignItems="center">
      <Image src="/empty1.svg" w={{base: "200px", md: "300px"}}/>
      <Text fontSize="lg" fontWeight="bold" mt={3}>Belum Belum Ada Kegiatan Aktif</Text>
      <Text fontSize={{ base: "12px", md:"sm" }}>Kegiatan terdaftar akan ada di sini</Text>

      <Button
        as={Link}
        to="/mhs/program"
        colorScheme='teal' variant='solid' mt={2}
        size={{base: "sm", md: 'md'}}
        height='48px'
        width='200px'
        borderRadius="sm"
      >
        Daftar Kegiatan
      </Button>
    </Flex>
  )
}
