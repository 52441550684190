import { Link } from "react-router-dom";
import { Skeleton, Flex, SkeletonCircle, Box, SkeletonText, Stack, Center } from '@chakra-ui/react'

const SkeletonData = () => {
  return(
  <>
    <Flex flexDir={{ base:"column", md: "row" }}>
      <Box w={{ base: "full", md: "359px" }} h="126" bg="white" p={3} mt={3} mr={2}>
        <Skeleton height='25px' />
        <Skeleton mt={2} height='70px' />
      </Box>
      <Box w={{ base: "full", md: "359px" }} h="126" bg="white" p={3} mt={3} mr={2}>
        <Skeleton height='25px' />
        <Skeleton mt={2} height='70px' />
      </Box>
      <Box w={{ base: "full", md: "359px" }} h="126" bg="white" p={3} mt={3} mr={2}>
        <Skeleton height='25px' />
        <Skeleton mt={2} height='70px' />
      </Box>
    </Flex>
  </>
  )

}

export default SkeletonData;
