
import { useState, useEffect } from "react";
import Axios from "services/AxiosConfig"
import { useParams } from "react-router-dom"

import { Text, HStack, Box, Flex, Icon, Skeleton, useToast,  Alert, AlertIcon, AlertTitle, AlertDescription, } from "@chakra-ui/react"

import ListPembimbing from "components/admin/ListPembimbing"

import {  Button, Select } from '@arco-design/web-react';
import { IconStar, IconSettings } from '@arco-design/web-react/icon';
const Option = Select.Option;
const ButtonGroup = Button.Group;

export default function BimbinganResult(){

  let {semester, tahun_akademik} = useParams();
  const toast = useToast();

  const [dosen, setDosen] = useState([]);
  const [mahasiswa, setMahasiswa] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dpl, setDpl] = useState("")
  const [mhsBimbingan, setMahasiswaBimbingan] = useState("")
  const [error, setError] = useState([])
  const [loadingContent, setLoadingContent] = useState(false)

  const [dataBimbingan, setDataBimbingan] = useState([])


  useEffect(() => {
      getDataDosen();
      getDataMahasiswa();
      getDataBimbingan();
  },[]);


  const getDataBimbingan = async () =>{
    setLoadingContent(true);
    await Axios.post("admin/getDataBimbingan", {semester, tahun_akademik}).then(({data}) =>{
      setLoadingContent(false);
      setDataBimbingan(data)
    }).catch(error => console.log(error))
  }

  const getDataDosen = async () => {
    setLoading(true)
    await Axios.get("admin/getDataDosen").then(({data}) =>{
      setDosen(data);
      setLoading(false)
    }).catch(error => console.log(error))
  }

  const getDataMahasiswa = async () => {
    setLoading(true)
    await Axios.post("admin/getDataMahasiswa", {semester, tahun_akademik})
    .then(({data}) =>{
      setMahasiswa(data);
      setLoading(false)
    }).catch(error => console.log(error))
  }

  const simpanBimbingan = async () => {
    if(dpl === undefined && mhsBimbingan.length === 0 ){
      return;
    }else{
      setLoading(true)
      await Axios.post("admin/simpanDataBimbingan",
      {
          semester, tahun_akademik, dpl, mhsBimbingan
      })
      .then(res => {
        if(res.status === 201){
          setLoading(false);
          setMahasiswaBimbingan("")
          setDpl("")

          if(res.data.errors.length > 0) {
            setError(res.data.errors)
          }
          getDataBimbingan();

          toast({
            title: 'Berhasil',
            description: `${res.data.message}`,
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });

          setTimeout(() => {
            setError([])
          }, 5000)

        }
      })
      .catch(err => {
        setLoading(false)
        toast({
          title: 'Gagal menyimpan',
          description: `data sudah ada!`,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      })
    }
  }

  return(
    <>
      <Text as="b" fontSize="lg">Daftar bimbingan 2022 / Ganjil</Text>
        <Box w="100%" bg="white"  mt={3} p={4} >
          <Text>Form Tambah Bimbingan</Text>
          <HStack spacing="15px" mt={3}>
            <Box>
              <Select
                  placeholder='Pilih DPL'
                  style={{ width: 300 }}
                  value={dpl}
                  allowClear
                  showSearch
                  loading={loading}
                  onChange={e => setDpl(e)}
                  disabled={loading}
                >
                {dosen.map((item, index) => (
                   <Option key={item.id_dosen} value={item.nama_dosen}>
                     {item.nama_dosen}
                   </Option>
                 ))}
                </Select>
            </Box>
            <Box>
              <Select
                  placeholder='Pilih pendaftar'
                  style={{ width: 500}}
                  mode='multiple'
                  value={mhsBimbingan}
                  loading={loading}
                  onChange={e => setMahasiswaBimbingan(e)}
                  disabled={loading}
                >
                {mahasiswa.map((item, index) => (
                   <Option key={index} value={item.nim}>
                     {item.nim}
                   </Option>
                 ))}
                </Select>
            </Box>
            <Button type='primary' disabled={loading} loading={loading} onClick={simpanBimbingan}>Simpan Bimbingan</Button>
          </HStack>
        </Box>

        {
          error.length > 0 && (
            <Message data={error} />
          )
        }

        <br /><br />
        <ButtonGroup>
          <Button type='primary' icon={<IconStar />}>Cetak</Button>
          <Button type='primary' icon={<IconSettings />}>Setting</Button>
        </ButtonGroup>

      <Header />

      {
        loadingContent === true ? (
          <Loader />
        ):
          dataBimbingan.map((item, index) => (
            <ListPembimbing data={item} getDataBimbingan={getDataBimbingan} semester={semester} tahunAkademik={tahun_akademik} key={item.index} />
          ))
      }

    </>
  )
}


const Header = () => {
  return(
    <HStack w="full" p={2} mt="10px" spacing="10px" fontSize="12px">
      <Box as={Flex} w="250px">
        <Box>
          <Text fontWeight="medium">Nama DPL</Text>
        </Box>
      </Box>
      <Box w="200px">
        <Text fontWeight="medium">Jumlah Bimbingan</Text>
      </Box>
      <Box w="150px">
        <Text fontWeight="medium">Sekolah</Text>
      </Box>
      <Box w="150px">
        <Text fontWeight="medium">Jenis Kegiatan</Text>
      </Box>
      <Box w="100px">
        <Text fontWeight="bold">Input Nilai</Text>
      </Box>
      <Box>
        <Text fontWeight="bold" ml="15px">Aksi</Text>
      </Box>
    </HStack>
  )
}


const Loader = () =>{
  return(
    <>
      <Skeleton height='50px' />
      <Skeleton mt={3} height='50px' />
      <Skeleton mt={3} height='50px' />
      <Skeleton mt={3} height='50px' />
      <Skeleton mt={3} height='50px' />
    </>
  )
}

function Message({data}) {
  return(
    <Alert status='error' mt={3}>
      <AlertIcon />
      <Box flex='1'>
        <AlertTitle>Error!</AlertTitle>
        <AlertDescription display='block'>
          {
            data.map(item => (
              <ul style={{marginLeft: "20px"}}>
                <li>{item}</li>
              </ul>
            ))
          }
        </AlertDescription>
      </Box>
    </Alert>

  )
}
