import { Box,  Spinner, Flex } from "@chakra-ui/react";

const Loader = ({mihH = '40vh'}) => {
	return(
		<Box minH={mihH} as={Flex} justifyContent="center" alignItems="center">
			<Spinner color='#FAC213' />
		</Box>
	)
}

export default Loader;