import {
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton
} from '@chakra-ui/react'

const Alerts = ({status, message, setError}) => {

  const handleClick = () => setError(false)

  return(
    <Alert status={status} my={4}  variant='subtle'>
      <AlertIcon />
      <AlertDescription>{message}</AlertDescription>
      <CloseButton position='absolute' right='8px' top='8px' onClick={handleClick}/>
    </Alert>
  )
}

export default Alerts;
