
import { useParams, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import Axios from "services/AxiosConfig";
import { Text, Box, Spinner, Flex, Table, useToast, Thead, HStack, Icon, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react"
import { MdOutlinePersonOutline } from "react-icons/md";

import { Input, Select, Button, Pagination, Space, Popconfirm, Tooltip } from '@arco-design/web-react';
import { IconDelete } from '@arco-design/web-react/icon';

const InputSearch = Input.Search;
const Option = Select.Option;
const options = ['Beijing', 'Shanghai', 'Guangzhou', 'Disabled'];


export default function ResultPeserta() {

  let navigate = useNavigate();
  let toast = useToast();

  let {semester, tahun_akademik} = useParams();

  const [ pendaftar, setPendaftar ] = useState([])
  const [ total, setTotal ] = useState(null)
  const [ loading, setLoading ] = useState(false);
  const [ search, setSearch ] = useState('');
  const [ datacenter, setDatacenter ] = useState({})

  const [ filter, setFilter ] = useState({
    prodi: '',
    kegiatan: '',
    jenisPeserta: ''
  });


  useEffect(() => {
    getDataPendaftar();
    getDatacenter()
  }, [])

  const getDataPendaftar = async () => {
    setLoading(true)

    await Axios.post(`admin/getDataPendaftar/10/1`,{
      semester, tahun_akademik
    }).then(({data}) => {
      setLoading(false)
      setTotal(data.total)
      setPendaftar(data.users)
    })
  }

  const getDatacenter = async () => {
    await Axios.get("admin/getDatacenter").then(({data}) => {
      setDatacenter(data)
    })
  }

  const getDataSearch =  () => {
    setLoading(true)
     Axios.post("admin/getDataSearchPendaftar",{search})
     .then(({data}) => {
       setLoading(false)
       setSearch('')
       setPendaftar(data)
     })
  }


  const getDataFilterProdi = (value) => {
    setLoading(true)
    let updateData = {};
    updateData = {prodi: value}

    setFilter(filter => ({
      ...filter,
      ...updateData
    }));

    Axios.post('admin/getDataFilterpendaftar', {filter})
          .then(({data}) => setPendaftar(data))
          setLoading(false)
  }

  const getDataFilterKegiatan = async (value) => {
    setLoading(true)
    let updateData = {};
    updateData = {kegiatan: value}

    setFilter(filter => ({
      ...filter,
      ...updateData
    }));

    await Axios.post('admin/getDataFilterpendaftar', {filter})
          .then(({data}) => setPendaftar(data));
    setLoading(false)
  }

  const getDataPageniation = async (e) => {
    setLoading(true)
    await Axios.post(`admin/getDataPendaftar/10/${e}`,{
      semester, tahun_akademik
    }).then(({data}) => {
      setTotal(data.total)
      setPendaftar(data.users)
      setLoading(false)
    })
  }

  const getDataPageniationSize = async (e) => {
    setLoading(true)
    await Axios.post(`admin/getDataPendaftar/${e}/1`,{
      semester, tahun_akademik
    }).then(({data}) => {
      setTotal(data.total)
      setPendaftar(data.users)
      setLoading(false)
    })
  }

  return(
    <Box  minH="360px" w="full" position="relative">
      <Text as="b" fontSize="lg">Pendaftar tahun akdemik 2022 / Ganjil</Text>
      <Box w="full" h="140px" bg="white" my={4} style={{fontSize: "12px"}} p={3}>
        <Tabs variant='enclosed'>
          <TabList>
            <Tab fontSize="sm">Filter data</Tab>
            <Tab fontSize="sm">Detail</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>

              <HStack spacing='10px' mt={2}>
                <Box w="300px">
                    <InputSearch
                      loading={loading}
                      allowClear
                      placeholder='Cari mahasiswa'
                      onChange={e => setSearch(e)}
                      value={search}
                      name="search"
                      onPressEnter={getDataSearch}
                    />

                </Box>
                <Box>
                  <Select
                    placeholder='Program studi'
                    style={{ width: 260 }}
                    onChange={getDataFilterProdi}
                  >
                  {datacenter.prodi?.map((item, index) => (
                    <Option key={item.id_prodi}  value={item.id_prodi} >
                      {item.nama_prodi}
                    </Option>
                  ))}
                  </Select>
                </Box>
                <Box>
                  <Select
                    placeholder='Program'
                    style={{ width: 140 }}
                    onChange={getDataFilterKegiatan}
                  >
                  {datacenter.kegiatan?.map((item, index) => (
                    <Option key={item.id}  value={item.nama_kegiatan}>
                      {item.nama_kegiatan}
                    </Option>
                  ))}
                  </Select>
                </Box>
                <Box>
                  <Select
                    placeholder='Lokasi'
                    style={{ width: 140 }}
                  >
                  {datacenter.lokasi?.map((item, index) => (
                    <Option key={item.id}  value={item.nama_sekolah}>
                      {item.nama_sekolah}
                    </Option>
                  ))}
                  </Select>
                </Box>
               <Button type='outline' status="danger" onClick={getDataPendaftar}>Reset</Button>
              </HStack>


            </TabPanel>
            <TabPanel>
              <p>Detail</p>
            </TabPanel>
          </TabPanels>
        </Tabs>

      </Box>

      <Box as={Flex} top="50%" position="absolute" left="50%" d={loading ? "block" : "none"}>
        <Spinner size='sm' />
      </Box>

      <Box w="full" style={{fontSize: "12px"}}>

        <HStack w="full" p={2} mt={2} spacing="10px">
          <Box as={Flex} w="250px">
            <Text fontWeight="bold">Nama/NIM</Text>
          </Box>
          <Box w="200px">
            <Text fontWeight="bold">Program Studi</Text>
          </Box>
          <Box w="150px">
            <Text fontWeight="bold">Lokasi</Text>
          </Box>
          <Box w="150px">
            <Text fontWeight="bold">Jenis Kegiatan</Text>
          </Box>
          <Box w="50px">
            <Text fontWeight="bold">Nilai</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Aksi</Text>
          </Box>
        </HStack>

        {
          pendaftar.map((item, index) =>(
            <HStack bg="white" w="full" p={2} mt={2} spacing="10px" key={index}>
              <Box as={Flex} w="250px">
                <Icon as={MdOutlinePersonOutline} fontSize="30px" mr={3} />
                <Box>
                  <Text>{item?.nama_lengkap}</Text>
                  <Text color="gray.500">{item?.nim}</Text>
                </Box>
              </Box>
              <Box w="200px">
                <Text fontWeight="medium">{item?.nama_prodi}</Text>
              </Box>
              <Box w="150px">
                <Text fontWeight="medium">{item?.lokasi}</Text>
              </Box>
              <Box w="150px">
                <Text fontWeight="medium">{item?.jenis_kegiatan}</Text>
              </Box>
              <Box w="50px">
                <Text fontWeight="bold">E</Text>
              </Box>
              <Box>
                <Text fontWeight="bold">
                   <Space size='xs'>
                      <Button size="mini" type='primary' onClick={() => navigate(`${item.id}/edit`)}>Ubah</Button>
                      <Popconfirm
                       title='Are you sure you want to delete?'
                        onOk={
                          async () => {
                              await Axios.delete(`admin/deletePeserta/${item.id}`)
                              .then(({data}) => {

                                let newDatas = pendaftar.filter(t => t.id !== item.id);
                                setPendaftar(newDatas)
                                 toast({
                                  title: 'Berhasil',
                                  description: `${data.message}`,
                                  status: 'success',
                                  duration: 5000,
                                  isClosable: true,
                                  position: 'top-right',
                                });
                              }).catch(err => {
                                const {status, data: {message}} = err.response;
                                  toast({
                                   title: `Gagal (${status})!`,
                                   description: `${message}`,
                                   status: 'error',
                                   duration: 5000,
                                   isClosable: true,
                                 })
                              })
                            }
                        }
                     >
                      <Tooltip content='Hapus' position='left'>
                       <Button size="mini" status='danger'>Hapus</Button>
                      </Tooltip>
                     </Popconfirm>
                    </Space>
                </Text>
              </Box>
            </HStack>

          ))
        }

        <Box as={Flex} justifyContent="right" w="full" mt={6}>
          <Pagination  showTotal onPageSizeChange={getDataPageniationSize}  total={total} sizeCanChange onChange={getDataPageniation} />
        </Box>

      </Box>

    </Box>
  )
}
