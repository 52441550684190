import { useState, useEffect } from 'react';
import Axios from 'services/AxiosConfig';
import { useNavigate, useParams } from 'react-router-dom';
import { Link, Text, Box, Button, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, HStack, Select, Stack, Radio, RadioGroup, Center, useToast } from "@chakra-ui/react";
import { ArrowBackIcon, RepeatIcon } from '@chakra-ui/icons'
import Loader from 'helpers/Loader';
import baseURL from 'services/BaseUrl';


export default function EditPeserta() {

	const {id} = useParams();
	let navigate = useNavigate();
	let toast = useToast();

	const[loading, setLoading] = useState(false);
	const[pendaftar, setPendaftar] = useState();
	const[lokasi, setLokasi] = useState([]);
	const[jenisKegiatan, setJenisKegiatan] = useState([]);
	const[loadingUpdate, setLoadingUpdate] = useState(false);

	useEffect(() => {
		getInfoPendaftar();
	},[]);

	const getInfoPendaftar = async () => {
		setLoading(true)
		await Axios.get('admin/getInfoPendaftar/'+id)
				.then(({data}) => {
					setPendaftar(data.pendaftar)
					setLokasi(data.lokasi)
					setJenisKegiatan(data.kegiatan)
					setLoading(false)
				})
				.catch(err => {
					console.log(err)
					setLoading(false)
				})
	}

	const handleChange = e => {
		const name 	= e.target.name,
				value = e.target.value;

		setPendaftar({...pendaftar, [name] : value})
	}

	const handleChangeRadio = e => {
		setPendaftar({...pendaftar, ['ukuran_baju'] : e})
	} 

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoadingUpdate(true)
		await Axios.post('admin/updatePendaftar', pendaftar)
				.then(res => {
					const {status, data: {message}} = res;
					toast({
	                title: 'Sukses!',
	                description: message,
	                status: 'success',
	                duration: 5000,
	                isClosable: true,
	                position: "bottom"
	              });
					setLoadingUpdate(false)
				})
				.catch(err => {
					setLoadingUpdate(false)
					const {status, data: {message}} = err.response;
		          toast({
		            title: `Gagal (${status})!`,
		            description: `${message}`,
		            status: 'error',
		            duration: 5000,
		            isClosable: true,
		          })
				})
	}

	return(
		<>
			{
				loading ? <Loader /> : (

					<>
						<Text  fontSize="xl" as="b">Edit Pendaftar </Text>
			<br />
			 <Button onClick={() => navigate(-1)} leftIcon={<ArrowBackIcon/>} mt="10px" colorScheme='red' size='sm' borderRadius="xs">Kembali</Button>
					<Box w="100%" bg="#fff" mt={2} p={5}>
						<form onSubmit={handleSubmit}>
							<FormControl w="100%" mb={5} isRequired>
								<FormLabel fontSize="sm" >Lokasi Kegiatan</FormLabel>
								  <Select placeholder='Lokasi' onChange={e => handleChange(e)} name="lokasi" borderRadius="xs" size="sm">
									  {
									  	lokasi.map((item, i) => (
									  		<option key={item?.id} value={item?.nama_sekolah} selected={pendaftar?.lokasi === item.nama_sekolah}>{item?.nama_sekolah}</option>
									  	))
									  }
									</Select>
								</FormControl>

								<HStack spacing="10px" mb={5}>
									<FormControl w="50%" isRequired>
									  <FormLabel fontSize="sm">Jenis Kegiatan</FormLabel>
									  <Select placeholder='Jenis kegiatan' borderRadius="xs" size="sm" onChange={e => handleChange(e)} name="jenis_kegiatan">
										  {
										  	jenisKegiatan.map((item, i) => (
										  		<option key={item?.id} value={item?.nama_kegiatan} selected={pendaftar?.jenis_kegiatan === item.nama_kegiatan}>{item?.nama_kegiatan}</option>
										  	))
										  }
										</Select>
									  {/*<FormHelperText>We'll never share your email.</FormHelperText>*/}
									</FormControl>
									<FormControl w="50%" isRequired>
									  <FormLabel fontSize="sm">Jenis Kepesertaan</FormLabel>
										  <Select placeholder='Jenis kepesertaan' borderRadius="xs" size="sm" onChange={e => handleChange(e)} name="jenis_kepesertaan">
											  <option value='Perorangan' selected={pendaftar?.jenis_kepesertaan === 'Perorangan'}>Perorangan</option>
											  <option value='Kelompok' selected={pendaftar?.jenis_kepesertaan === 'Kelompok'}>Kelompok</option>
											</Select>
									  {/*<FormHelperText>We'll never share your email.</FormHelperText>*/}
									</FormControl>
								</HStack>
								<HStack spacing="10px" mb={5}>
									<FormControl isRequired>
									  <FormLabel fontSize="sm">No HP</FormLabel>
									  <Input type='number' onChange={e => handleChange(e)} name="no_hp" placeholder="Jenis kegiatan..." borderRadius="xs" size="sm" defaultValue={pendaftar?.no_hp} />
									  {/*<FormHelperText>We'll never share your email.</FormHelperText>*/}
									</FormControl>
									
									<FormControl isRequired>
									  <FormLabel fontSize="sm">Tanggal Bayar</FormLabel>
									  <Input type='date' borderRadius="xs" onChange={e => handleChange(e)} name="tgl_bayar" size="sm" defaultValue={pendaftar?.tgl_bayar}  />
									  {/*<FormHelperText>We'll never share your email.</FormHelperText>*/}
									</FormControl>
								</HStack>

								<FormControl mb={5} isRequired>
									  <FormLabel fontSize="sm">Ukuran Baju</FormLabel>
										   <RadioGroup defaultValue={pendaftar?.ukuran_baju} onChange={e => handleChangeRadio(e)} name="ukuran_baju">
					                    <Stack spacing={7} direction='row'>
					                      <Radio colorScheme='green' value='S'>
					                        S
					                      </Radio>
					                      <Radio colorScheme='green' value='M'>
					                        M
					                      </Radio>
					                      <Radio colorScheme='green' value='L'>
					                        L
					                      </Radio>
					                      <Radio colorScheme='green' value='XL'>
					                        XL
					                      </Radio>
					                      <Radio colorScheme='green' value='XXL'>
					                        XXL 
					                      </Radio>
					                    </Stack>
					                  </RadioGroup>
									</FormControl>

								<HStack spacing="5px" mb={5}>
									<FormControl isRequired>
									  <FormLabel fontSize="sm">Surat Rekomendasi PA</FormLabel>
									  	<Box mr={3}>
									  		<Box border="1px dashed #718096" borderRadius="lg" p={3} cursor="pointer" _hover={{bg: '#EDF2F7'}}>
									  			<Link isExternal href={baseURL+'assets/upload/gambar/'+pendaftar?.bukti_bayar2}>{pendaftar?.bukti_bayar2}</Link>
									  		</Box>
									  	</Box>
									</FormControl>
									  <FormControl isRequired>
									  <FormLabel fontSize="sm">Bukti Bayar</FormLabel>
									  	<Box>
									  		<Box border="1px dashed #718096" p={3} borderRadius="lg" _hover={{bg: '#EDF2F7'}} cursor="pointer">
									  				<Link isExternal href={baseURL+'assets/upload/gambar/'+pendaftar?.bukti_bayar}>{pendaftar?.bukti_bayar}</Link>
									  		</Box>
									  	</Box>
									</FormControl>
									 <FormControl isRequired>
									  <FormLabel fontSize="sm">Bukti BTQ</FormLabel>
									  	<Box>
									  		<Box border="1px dashed #718096" p={3} borderRadius="lg" _hover={{bg: '#EDF2F7'}} cursor="pointer">
									  				<Link isExternal href={baseURL+'assets/upload/gambar/'+pendaftar?.btq}>{pendaftar?.btq}</Link>
									  		</Box>
									  	</Box>
									</FormControl>
								</HStack>
								<br />
								<Center>
									<Button type="submit" disabled={loadingUpdate} isLoading={loadingUpdate} loadingText="Submitting" colorScheme='teal' size='md' borderRadius="sm" leftIcon={<RepeatIcon/>}>Update</Button>
								</Center>							
						</form>

					</Box>
					</>

				)
			}
		</>
	)
}