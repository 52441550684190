
import { useEffect, useState} from "react"
import Axios from "../../services/AxiosConfig"
import Select  from 'react-select'
import { useParams } from "react-router-dom";
import { Box, Text, Container, Button, HStack, Alert,  Stack, Input, Flex, AlertIcon, AlertTitle, AlertDescription, Icon, List, ListItem, ListIcon, FormControl, FormLabel,Center, Spinner } from "@chakra-ui/react";
import { FaArrowRight, FaSearchLocation, FaSchool } from "react-icons/fa";
import { MdSupervisedUserCircle, MdHourglassBottom } from "react-icons/md";

import InfoSekolah from "../../components/mahasiswa/InfoSekolah"

const PilihanProgram = () => {

  const {slug} = useParams();


   const [program, setProgram] = useState({});
   const [loading, setLoading] = useState(false);
   const [sekolah, setSekolah] = useState([]);
   const [search, setSearch] = useState('');
   const [infoSekolah, setInfoSekolah] = useState([]);
   const [loadingb, setLoadingb] = useState(false);

   const[infoMhs, setInfoMhs] = useState([]); 



   useEffect(() => {
     setLoading(true)
      Axios.post('mahasiswa/detailProgram',{slug}
      ).then(({data}) => {
        setProgram(data.program);
      }).catch(error => {
        console.log(error)
      });

      setLoading(false)
      getDataSekolah();
      getDataMahasiswa();
  }, []);

  const getDataMahasiswa = async () => {
    await Axios.get("mahasiswa/getInfoMahasiswa")
          .then(({data}) => {
            setInfoMhs(data.infoMhs);
          })
          .catch(err => console.log(err))
   }

  function getDataSekolah() {
    setLoading(true)
     Axios.get(
       'mahasiswa/getDataSekolah'
     ).then(({data}) => {

       let newSkolah = []

       data.sekolah.map(item => {
         newSkolah.push({
           label: item.nama_sekolah,
           value: item.id
         })
       });

       setSekolah(newSkolah)
       setLoading(false)

     }).catch(error => {
       console.log(error)
       setLoading(false)

     });

  }

  const handleSearch = () => {
    setLoadingb(true)
      Axios.post(
          'mahasiswa/getInfoSekolah',{
          search
        }
      ).then(({data}) => {
        setInfoSekolah(data.dataInfo)
        setLoadingb(false)

      }).catch(error => {
        console.log(error)
        setLoadingb(false)
      });
  }

  return(
    <>
    <Box bg={{base: "", md: "#EDEDED"}}>
      <Box  as={Container} maxW="container.xl" py={3}>
        <Box>
          {loading && (
            <Center><Spinner size='sm' /></Center>
          )}
        </Box>
         <Box m={{base: 0, md: 8}}>
           <Text fontSize={{base: "2xl", md: '5xl'}} fontWeight="bold" style={{textTransform: "capitalize"}}>{program.nama_kegiatan}</Text>
           <Text mt={{base: 0, md: "-5px"}} fontSize={{base: "13px", md: 'md'}}>{program.deskripsi}</Text>
             <Button
               d={{ base: "none", md: "block" }}
               mt={3}
               size='md'
               height='48px'
               width='200px'
               borderRadius='sm'
               colorScheme='teal'
               variant='solid'
               rightIcon={<FaArrowRight />}
             >
               Tentang Program
             </Button>

             <Box w="full" p={{base: 3, md: 5}} bg="white" mt={4} borderRadius="sm" boxShadow="md" >

               <HStack spacing={3}>
                 <Box width="full">
                   <Select key={sekolah.id} options={sekolah} isLoading={loading} isDisabled={loading} isClearable={true} name={search} onChange={(e) => setSearch(e.value)}/>
                 </Box>
                    <Button leftIcon={<FaSearchLocation />} colorScheme='teal' size="md" borderRadius={1} onClick={handleSearch} isLoading={loadingb}   loadingText='Loading...'>
                    <Text fontSize={{ base: "12px", md: "14px" }}>Cek Lokasi</Text>
                  </Button>
               </HStack>
             </Box>
         </Box>
       </Box>
    </Box>



      {infoSekolah.sekolah  ?  (
          <InfoSekolah data={infoSekolah} mhs={infoMhs} slug={slug} />
      ) : (
        <Box>
          <Alert
            status='warning'
            variant='subtle'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
            height='200px'
          >
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
              Silahkan pilih sekolah!
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
              Silahkan memilih sekolah untuk melihat informasi lebih lanjut!.
            </AlertDescription>
          </Alert>
        </Box>
      )
    }
    </>
  )
}

export default PilihanProgram;
