
import { useEffect, useState, useRef } from "react";
import { Text, useToast, Box, Spinner, Center, Flex, FormControl, FormLabel, FormErrorMessage, FormHelperText, Input, Stack, HStack, VStack, InputGroup, InputLeftAddon, Select, Icon, Button} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import Axios from "../../services/AxiosConfig";
import { CKEditor } from 'ckeditor4-react';
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { FaImage, FaTimesCircle, FaCloudUploadAlt} from "react-icons/fa";

const month = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];


export default function EditLogbook() {

	const {id}  = useParams();
	let toast 	= useToast();

	const year = new Date().getFullYear();
 	let navigate = useNavigate();

	let tgl = [];
	for(let i = 1; i<=31; i++) {
		tgl.push(i);
	} 

	const[logbook, setLogbook] 	= useState("");
	const[loading, setLoading]	= useState(false);
	const[loadingPost, setLoadingPost]	= useState(false);

	const inputRef = useRef(null);
	const[lampiran, setLampiran] 				= useState([]);
	const[mingguKe, setMingguKe]				= useState("");
	const[tanggal, setTanggal]					= useState("");
	const[bulan, setBulan]						= useState("");
	const[tahun, setTahun]						= useState("");
	const[errorFileCount, setFileErrorCount] 	= useState(false);
	const[imageUrl, setImageUrl] 				= useState([])
	const[modalPreview, setModalPreview] 		= useState(false)

	const[namaKegiatan, setNamaKegiatan] = useState("")
	const[tujuanKegiatan, setTujuanKegiatan] = useState("")
	const[catatanKegiatan, setCatatanKegiatan] = useState("")
	const[pemecahanMasalah, setPemecahanMasalah] = useState("")
	const[kesimpulan, setKesimpulan] = useState("")
	const[kegiatanSelanjutnya, setkegiatanSelanjutnya] = useState("");
	const[idLogbook, setIdLogbook] = useState("");

	const[lampiran1, setLampiran1] = useState([])

	useEffect(() => {
		getLogbook();
	},[]);

	const getLogbook = async () => {
		setLoading(true)
		await Axios.get("mahasiswa/logbook/detail/"+id)
			.then(({data}) => {

				const dt = data.logbook;

				const tanggalUtuh = dt.tgl_kegiatan;
				const pisah_tgl	= tanggalUtuh.split(" ");

				setTanggal(pisah_tgl[0]);
				setBulan(pisah_tgl[1]);
				setTahun(pisah_tgl[2]);
				setLogbook(data.logbook);
				setMingguKe(dt.mingguKe)
				setNamaKegiatan(dt.nama_kegiatan);
				setTujuanKegiatan(dt.tujuan_kegiatan)
				setCatatanKegiatan(dt.catatan)
				setPemecahanMasalah(dt.pemecahan_masalah)
				setKesimpulan(dt.kesimpulan)
				setkegiatanSelanjutnya(dt.rencana)
				setIdLogbook(dt.id);
				setLoading(false)
			})
			.catch(err => {
	            const {status, data: {message}} = err.response;
				setLoading(false)
	              toast({
	                title: `Gagal (${status})!`,
	                description: `${message}`,
	                status: 'error',
	                duration: 5000,
	                isClosable: true,
	              });
          });
	}

	const handleChangeCKEfitor = (e, input) => {
	  	const data = e.editor.getData();
	  	if(input === 'namaKegiatan') {
	  		setNamaKegiatan(data)
	  	}else if(input === 'tujuan') {
	  		setTujuanKegiatan(data)
	  	}else if(input === 'catatan'){
	  		setCatatanKegiatan(data)
	  	}else if(input === 'pemecahanMasalah') {
	  		setPemecahanMasalah(data)
	  	}else if(input === 'kesimpulan'){
	  		setKesimpulan(data)
	  	}else if(input === "rencanaSelanjutnya"){
	  		setkegiatanSelanjutnya(data)
	  	}
	  }

	  const handleOpenFileInput = () => {
	    inputRef.current.click();
	 };

	 const handleChangeLampiran =  (e) => {
	 	const filesUpload = e.target.files;
	 	setLampiran1(filesUpload)
	 	const newArray = Array.from(filesUpload);

	 	const BreakError = {};

	 	try {
		  newArray.forEach((file, index) => {

		    if (index === 4) {
		      throw BreakError;
		    }
		  	const objectUrl = URL.createObjectURL(file);
		    setLampiran(oldValue =>  [...oldValue, {
		    	'file': file,
		    	'url': objectUrl
		    }]);
		  });
		} catch (err) {
		  	setFileErrorCount(true)
		  if (err !== BreakError) {
		  		setFileErrorCount(true)
		  };
		}

	 }

	 function bytesToSize(bytes) {
	     let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	     if (bytes == 0) return '0 Byte';
	     let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
	     return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
	  }

	  const handRemoveLampiran = (e,index) => {
	  		setLampiran((lampiran) => lampiran.filter((_, index) => index !== 0));
	  		if(lampiran.length <= 4) {
	  			setFileErrorCount(false);
	  		}else{
	  			setFileErrorCount(true);
	  		} 
	  }

	  const handleClickImage = (url)  => {
	  		setImageUrl(url)
	  		setModalPreview(true)
	  }

	  const handleSubmitForm = () => {

		  	setLoadingPost(true);
		 	const newArray1 = Array.from(lampiran1);
		  	let formData = new FormData();

		  	newArray1.forEach((file, index) => {
		  		formData.append("lampiran"+index, file);
		  	});
		  	
		  	formData.append("idLogbook", idLogbook);
		  	formData.append("mingguKe", mingguKe);
		  	formData.append("tanggal", tanggal);
		  	formData.append("bulan", bulan);
		  	formData.append("tahun", tahun);
		  	formData.append("namaKegiatan", namaKegiatan);
		  	formData.append("tujuanKegiatan", tujuanKegiatan);
		  	formData.append("catatan", catatanKegiatan);
		  	formData.append("pemecahanMasalah", pemecahanMasalah);
		  	formData.append("kesimpulan", kesimpulan);
		  	formData.append("kegiatanSelanjutnya", kegiatanSelanjutnya);


		  	Axios.post("mahasiswa/logbook/update", formData, {headers: {
		        "Content-Type" : "multipart/form-data"
		      }})
		  	.then(res => {
		  		const {status, data: {message}} = res;
		  		toast({
	                title: 'Sukses!',
	                description: message,
	                status: 'success',
	                duration: 5000,
	                isClosable: true,
	              });
		  		 navigate("/mhs/logbook");
		  		 setLoadingPost(false)
		  	}).catch(error => {
		  		const {status, data: {message}} = error.response;
		          setLoadingPost(false)
		          toast({
		            title: `Gagal (${status})!`,
		            description: `${message}`,
		            status: 'error',
		            duration: 5000,
		            isClosable: true,
		          })
		  	})


		  }

	return(
		<>
			{
				loading ? <LoadingBox /> : (
					<>
						<Box w="100%">
							<Box px={2} py={3} bg="#F5DF99">
					        <Text fontWeight="bold">Edit logbook</Text>
					      </Box>

					      <Box mt={3} bg="white" w="100%" p={3} style={{ fontSize: "10px" }}>
				      		 <Stack spacing={4} direction={['column', 'row']}>
					              <Box w={{base:"100%", md:"35%" }}>
					                <FormControl id="firstName" isRequired>
					                  <FormLabel fontSize="14px">Kegiatan</FormLabel>
					                  <InputGroup borderRadius="sm" size="sm">
									    <InputLeftAddon children='Minggu Ke-' />
									    <Input placeholder='ex. 5' type="number" value={mingguKe} onChange={e => setMingguKe(e.target.value)} />
									  </InputGroup>
					                </FormControl>
					              </Box>
					              <Box w="full">
					                <FormControl id="lastName">
					                  <FormLabel fontSize="14px">Waktu kegiatan</FormLabel>
					                  	<Box as={Flex} flexDir={{base: "column", md: "row"}} >
					                  		<InputGroup borderRadius="sm" size="sm" w="200px" mr={3} w={{base: "full", md:"200px"}} my={{base: "1", md: 0 }}>
											    <InputLeftAddon children='Tanggal' />
											    <Select placeholder='--pilih--' onChange={e => setTanggal(e.target.value)}>
												  {
												  	tgl.map((item, index) => (
												  		<option selected={Number(tanggal) === item} value={item}  key={index}>{item}</option>
												  	))
												  }
												</Select>
											  </InputGroup>
											  <InputGroup borderRadius="sm" size="sm" w="200px" mr={3} w={{base: "full", md:"200px"}} my={{base: "1", md: 0 }}>
											    <InputLeftAddon children='Bulan' />
												    <Select placeholder='--pilih--' onChange={e => setBulan(e.target.value)}>
														 {
														 	month.map((item, index) => (
														 		<option selected={bulan === item} value={item} key={index}>{item}</option>
														 	))
														 }
													</Select>
											  </InputGroup>
											  <InputGroup borderRadius="sm" size="sm" w="200px" w={{base: "full", md:"200px"}} my={{base: "1", md: 0 }}>
											    <InputLeftAddon children='Tahun' />
												    <Input type="text" value={year} onChange={e => setTahun(e.target.value)} />
											  </InputGroup>
					                  	</Box>
					                </FormControl>
					              </Box>
					        </Stack>

					        <FormControl mt={7}>
				              <FormLabel fontSize="14px">Nama kegiatan / sub kegiatan</FormLabel>
				               <CKEditor
				               	onChange={e => handleChangeCKEfitor(e, 'namaKegiatan')}
					                initData={namaKegiatan}
					            />
				            </FormControl>

				            <FormControl mt={7}>
				              <FormLabel fontSize="14px">Tujuan kegiatan / sub kegiatan (sesuai program)</FormLabel>
				               <CKEditor
				               		onChange={e => handleChangeCKEfitor(e, 'tujuan')}
					                initData={tujuanKegiatan}
					            />
				            </FormControl>

				            <FormControl mt={7}>
				              <FormLabel fontSize="14px">Catatan rencana yang akan dilakukan</FormLabel>
				               <CKEditor
				               		onChange={e => handleChangeCKEfitor(e, 'catatan')}
					                initData={catatanKegiatan}
					            />
				            </FormControl>

				            <FormControl mt={7}>
				              <FormLabel fontSize="14px">Pemecahan masalah</FormLabel>
				               <CKEditor
				               		onChange={e => handleChangeCKEfitor(e, 'pemecahanMasalah')}
					                initData={pemecahanMasalah}
					            />
				            </FormControl>

				            <FormControl mt={7}>
				              <FormLabel fontSize="14px">Kesimpulan dan saran</FormLabel>
				               <CKEditor
				               		onChange={e => handleChangeCKEfitor(e, 'kesimpulan')}
					                initData={kesimpulan}
					            />
				            </FormControl>

				            <FormControl mt={7}>
				              <FormLabel fontSize="14px">Rencana kegiatan selanjutnya</FormLabel>
				               <CKEditor
				               		onChange={e => handleChangeCKEfitor(e, 'rencanaSelanjutnya')}
					                initData={kegiatanSelanjutnya}
					            />
				            </FormControl>

				            <FormControl mt={7}>
	              <FormLabel fontSize="14px">Lampiran kegiatan <small>(option)</small></FormLabel>
	              	 <Text fontSize={{ base: "12px", md: "12px" }} color="gray.400" mb={1}>Unggah multiple file/gambar dengan ukuran maksumal 5 MB, Maksimal 4 gambar. <br />
			          </Text>
			           {
			           	lampiran.length > 0 ? (
			           		<span>
			           			{
			           				lampiran.map(({file, url}, index) => (
			           					<div key={index}>
			           						<Box onClick={() => handleClickImage(url)} _hover={{bg: "#F5DF99", cursor: "pointer"}} w="full" p={2} border="1px dashed" my={1} as={Flex} justifyContent="space-between">
			           							<Box as={Flex} alignItems="center">
			           								<Icon as={FaImage} fontSize="xl" color='teal' mr={2} />
			           								<Text fontSize="12px">{file.name}</Text>
			           								<Text fontSize="10px" color="gray.500" ml={2}>({bytesToSize(file.size)})</Text>
			           							</Box>
			           							<Box justifyContent="center" alignItems="center" as={Flex}>
							                        <Icon 
							                        	as={FaTimesCircle}  
							                        	fontSize="md" 
							                        	_hover={{ cursor: "pointer", color: "red.500" }}
							                        	onClick={() => handRemoveLampiran(index)}
							                        />
							                      </Box>
			           						</Box>
			           					</div>
			           				))
			           			}
			           		</span>
			           	):("")
			           }

			           {
			           		errorFileCount && (
			           			<Text color="red.700">Hanya dapat mengupload 4 gambar</Text>
			           		)
			           }

			          {
			          	lampiran.length >= 4 ? "" : (
			          		<Box as={Flex} justifyContent="center" alignItems="center" w="full" bg="white" border="1px dashed" h="80px" >
				            <Button disabled={loading} colorScheme='teal' leftIcon={<FaCloudUploadAlt />} size='sm' borderRadius="sm" onClick={handleOpenFileInput}>
				              Pilih File
				            </Button>
				            <input ref={inputRef} multiple  style={{ display: "none" }} type="file" onChange={ e => handleChangeLampiran(e)} />
				          </Box> 
			          	) 
			          }
	            </FormControl>

	            <Center>
		             <Button
		              size='md'
		              height='48px'
		              width='250px'
		              mt={7}
		              mb={6}
		              colorScheme='teal'
		              borderRadius="sm"
		              onClick={() => handleSubmitForm()}
		              disabled={loadingPost}
		              isLoading={loadingPost}
		            >
		              Simpan logbook
		            </Button>
		           </Center>

					       </Box>

					    </Box>
					</>
				)	
			}
		</>
	)
}

const LoadingBox = () => {
	return(
		<Box w="full" as={Flex} justifyContent="center" alignItems="center">
			<Spinner color='green.700' />
		</Box>
	)
}