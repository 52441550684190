
import Axios from "../../services/AxiosConfig"
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom"
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Text,
  Badge,
  Container,
  useToast
} from '@chakra-ui/react';
import { FaUserCog, FaFileAlt, FaQuestionCircle, FaLock } from "react-icons/fa"
import { FiLogOut } from "react-icons/fi";


import { Link } from "react-router-dom"
 
export default function Simple() {

  let toast = useToast();
  let navigate = useNavigate();

  const[user, setUser] = useState([]);

  useEffect(() => {
    getInfoMhs();
  },[]);

  const getInfoMhs = async () => {
    await Axios.get("mahasiswa")
          .then(({data}) => {
            setUser(data.user)
          })
          .catch(error => {
            const {status, data: {data}} = error.response;
            toast({
              title: `Gagal (${status})!`,
              description: `${data}`,
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          });
  }

  const handleClick = () => {
     localStorage.removeItem("token");
     navigate("/login")
  }


  return (
    <Box minH="100vh" as={Container} maxW="container.xl" py={5}>
      <Flex flexDir={{base: "column", md: "row"}}  overflow="hidden">
        <Box w="600px" mb="5px">
          <Box bg="white" borderRadius="sm" p={3} mx={3}>
              <HStack spacing={3}>
                  <Avatar size="lg" src='' m={2} />
                <div >
                  <Text fontSize="lg" fontWeight="bold" style={{textTransform: "uppercase"}}>{user.nama_lengkap}</Text>
                  <Text fontSize="sm" color="gray.500" style={{textTransform: "capitalize"}}>{user.nama_prodi}</Text>
                  <Text fontSize="sm" color="gray.500">Universitas Muhammadiyah kendari</Text>
                </div>
              </HStack>
          </Box>

          <Box bg="white" borderRadius="sm" py={3} m={3}>
              <MenuSidebar icon={<FaUserCog/>} text="Profil KegiatanKu" url="/mhs/beranda/profile" active={true} />
              <MenuSidebar icon={<FaFileAlt/>} text="Dokumen Penting" url="/mhs/beranda/document" />
              <MenuSidebar icon={<FaQuestionCircle/>} text="FAQ"  url="/mhs/beranda/faq" />
              <MenuSidebar icon={<FaLock/>} text="Ganti Kata Sandi"  url="/mhs/beranda/password" />

              <HStack p={2} my={1} style={{cursor: "pointer"}} onClick={() => handleClick()}>
                <Text fontSize="20px" mr={1} ml={2}>{<FiLogOut/>}</Text>
                <Text fontSize="md" fontWeight="600">{"Keluar"}</Text>
              </HStack>
          </Box>

        </Box>


        <Box w="full" mx={3} borderRadius="sm">

          <Outlet />

        </Box>

      </Flex>

    </Box>
  );
}

const MenuSidebar = ({icon, text, status, url, active}) => {
    return(
      <>
      <HStack p={2} my={1} style={{cursor: "pointer"}} as={Link} to={url} >
        <Text fontSize="20px" mr={1} ml={2}>{icon}</Text>
        <Text fontSize="md" fontWeight="600">{text}</Text>
        {status &&
          <Badge colorScheme='red' borderRadius="md" pb="2px"><small>{status}</small></Badge>
        }
      </HStack>
      </>
    )
}
