
import { Box, Container, Flex, Text, HStack, Icon } from "@chakra-ui/react";
import { FaBell } from "react-icons/fa";

const Notifikasi = () => {
  return(
    <Box as={Container} maxW="container.lg">
      <Box as={Flex} bg="white" boxShadow="sm" borderRadius="md" justifyContent="space-between" p={4} alignItems="center">
        <Text fontSize="2xl" fontWeight="bold">Notifikasi</Text>
        <Text fontSize="sm" fontWeight="bold" color="blue.500">Sudah dibaca</Text>
      </Box>

      <Box as={Flex} bg="white" boxShadow="sm" borderRadius="sm" justifyContent="space-between" mt={4} p={4} borderBottom="1px" borderColor="gray.300">
        <HStack>
          <Icon as={FaBell} />
          <Box>
            <Text fontSize="14px">Hello ardiman selamat datang di MBKM FKIP UM Kendari, Silahkan isi data diri kamu dengan benar!</Text>
            <Text fontSize="12px" color="gray.400">16 Feb. 11:02</Text>
          </Box>
        </HStack>
      </Box>

      <Box as={Flex} bg="white" boxShadow="sm" borderRadius="sm" justifyContent="space-between" p={4} borderBottom="1px" borderColor="gray.300">
        <HStack>
          <Icon as={FaBell} />
          <Box>
            <Text fontSize="14px">Lengkapi Profil kamu untuk bisa mendaftar program-program Kampus Merdeka!</Text>
            <Text fontSize="12px" color="gray.400">16 Feb. 11:02</Text>
          </Box>
        </HStack>
      </Box>

    </Box>
  )
}

export default Notifikasi;
